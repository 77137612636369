import { useContext } from 'react'

import Papper from 'assets/light/papper.svg'
import Header from 'components/atoms/Header/Header'
import ImageHero from 'components/atoms/ImageHero'
import Results from 'assets/icons/shield-check.svg'
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary'
import { content } from 'utils/content'
import { AppContext } from 'context/AppContext'

function PictureRecommendations() {
  const { dosesOne } = useContext(AppContext)
  const { title, paragraph, button } = content[5]

  const routeVaccine = dosesOne?.vaccinationDate ? '/foto-dosis-dos' : '/foto-dosis-uno'

  return (
    <div>
      <Header />
      <ImageHero image={Papper} alt='image de calendario' />

      <div className='text-center px-4 px-lg-5 mx-lg-5'>
        <div className='paragraph'>
          <div className='py-lg-3'>
            <h2 className='py-3 pt-3 my-4 mb-lg-0 py-lg-0 py-lg-3'>{title}</h2>
          </div>
          <p className='text-center mb-lg-4'>{paragraph}</p>
        </div>

        <div className='message__conditions'>
          <div className='d-flex justify-content-start align-items-center conditions' key='1'>
            <img src={Results} alt='imagen de resultados' />
            <p className='ml-2 mb-0 pb-0 text-left'>
              Te sugerimos que tomes tus fotos en un lugar con luz y con una superficie plana.
            </p>
          </div>
        </div>
      </div>

      <div className='py-lg-0 py-5'></div>

      <div className='d-none d-lg-block'>
        <ButtonPrimary button={button} route={routeVaccine} />
      </div>
      <div className='fixed-bottom d-lg-none'>
        <ButtonPrimary button={button} route={routeVaccine} padding='0' />
      </div>
    </div>
  )
}

export default PictureRecommendations
