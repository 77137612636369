import { useRef, useState, useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Upload } from 'antd'
import Cropper from 'react-perspective-cropper'

import ArrowBack from 'assets/icons/arrowLeft.svg'
import LogoTerms from 'components/atoms/LogoTerms'
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary'
import LoadingShort from 'components/atoms/Loader/LoadingShort'
import AddImageMessage from 'components/atoms/AddImageMessage'
import { setImage } from 'services/imageService'
import { AppContext } from 'context/AppContext'

const { Dragger } = Upload

function PictureBack({ history }) {
  const { previewBack, setPreviewBack } = useContext(AppContext)
  const [cropState, setCropState] = useState()
  const [imageCropped, setImageCropped] = useState()
  const [continueNext, setContinueNext] = useState(false)
  const [savePicture, setSavePicture] = useState(false)
  const [blobDataTest, setBlobDataTest] = useState()

  const cropperRef = useRef()
  const canvasRef = useRef()

  const onDragStop = useCallback((s) => setCropState(s), [])
  const onChange = useCallback((s) => setCropState(s), [])

  /** Select the picture to upload */
  const onImgSelection = (e) => {
    if (e.fileList?.length > 0) {
      const fileList = e.fileList[0].originFileObj
      setImageCropped(fileList)
      prepareImg(fileList)
    }
  }

  /** Props to cropper */
  const draggerProps = {
    name: 'file',
    multiple: false,
    onChange: onImgSelection,
  }

  const editCropper = () => {
    setContinueNext(false)
    cropperRef.current.backToCrop()
  }

  const cropperSelected = async () => {
    try {
      const res = await cropperRef.current.done({
        preview: true,
        filterCvParams: {
          thMeanCorrection: 9,
          thMode: window.cv.ADAPTIVE_THRESH_GAUSSIAN_C,
        },
      })
      setContinueNext(true)
      setBlobDataTest(res)
      return res
    } catch (e) {
      console.log('error', e)
    }
  }

  const nextButtons = (imageCropped) => {
    setSavePicture(true)
    addCropped('back', imageCropped)
  }

  const takeAgain = () => {
    setImageCropped(undefined)
    setContinueNext(false)
    setSavePicture(false)
    setCropState()
  }

  const saveImage = (setter) => {
    const canvas = canvasRef.current.children[0].children[0]
    const dataURI = canvas.toDataURL()
    setter(dataURI)
    takeAgain()

    history.push('/foto-dosis-dos')
  }

  /** Validate if the file it's really an image */
  const prepareImg = (file) => {
    const reader = new FileReader()

    reader.onload = (e) => {
      if (!e.target.result.includes('data:image')) {
        history.push('/foto-dosis-dos')
        return alert('Solo se permiten imagenes.')
      }
    }
    reader.readAsDataURL(file)
  }

  /** Crop image */
  const addCropped = (key, file) => {
    const reader = new FileReader()

    reader.onload = () => {
      setImage(key, file, blobDataTest)
    }
    reader.readAsDataURL(file)
  }

  return (
    <>
      <div className='pt-lg-3'></div>
      <LogoTerms />
      <div className='px-4 pt-3 pt-lg-0'>
        <div className='row px-3 pb-3 d-flex align-items-center mb-lg-3'>
          <div className='col-2 ml-0 pl-0 d-lg-none'>
            {!imageCropped && (
              <Link to='/foto-dosis-dos'>
                <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
              </Link>
            )}
          </div>
          <div className='col-8 col-lg-12 conditions__title'>
            <h5 className='text-center mb-0'>Dorso de la cartilla</h5>
          </div>
        </div>
        <div className='container__canvas text-center d-flex justify-content-center align-items-center' ref={canvasRef}>
          <Cropper
            openCvPath='./opencv/opencv.js'
            ref={cropperRef}
            image={imageCropped}
            onChange={onChange}
            onDragStop={onDragStop}
            minWidth={window.innerWidth - 30}
            maxWidth={window.innerWidth / 1.3}
          />
        </div>

        {!imageCropped &&
          (previewBack?.length ? (
            <>
              <div className='d-none d-lg-block text-center image-input'>
                <div className='ani-show'>
                  <img src={previewBack} alt='foto reverso imagen' />
                </div>
              </div>

              <div className='d-lg-none text-center px-4 image-input'>
                <div className='ani-show'>
                  <img src={previewBack} alt='foto reverso imagen' />
                </div>
              </div>
            </>
          ) : (
            <AddImageMessage text='posterior, o de nuevo la frontal si no hay información' />
          ))}
      </div>

      {cropState?.loading && <LoadingShort />}
      <div className='col pl-lg-2 pr-lg-0'>
        <div className='button__primary'>
          <div className='row px-4 px-lg-5 mx-lg-5'>
            {!imageCropped && (
              <>
                <div className='col px-lg-0 d-none d-lg-block pl-lg-0 pr-lg-2'>
                  <Link to='/foto-dosis-dos' className='btn btn-outline-secondary'>
                    Atrás
                  </Link>
                </div>

                <div className='col pl-lg-2 pr-lg-0 d-none d-lg-block'>
                  <Dragger {...draggerProps}>
                    <button className='btn btn-primary'>Anexar Foto</button>
                  </Dragger>
                </div>

                <div className='fixed-bottom d-lg-none'>
                  <Dragger {...draggerProps}>
                    <ButtonPrimary button='Anexar Foto' route='#' padding='0' />
                  </Dragger>
                </div>
              </>
            )}
            {cropState &&
              (!savePicture ? (
                <>
                  <div className='col px-lg-0 pr-lg-2 pr-2 pl-0'>
                    <button className='btn btn-outline-secondary' onClick={editCropper}>
                      Editar
                    </button>
                  </div>
                  <div className='col pl-lg-2 pl-2 pr-0'>
                    {!continueNext ? (
                      <button className='btn btn-primary' onClick={cropperSelected}>
                        Aceptar
                      </button>
                    ) : (
                      <button className='btn btn-primary' onClick={() => nextButtons(imageCropped)}>
                        Siguiente
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className='col px-lg-0 pl-0 pr-2 pr-lg-2'>
                    <button className='btn btn-outline-secondary' onClick={takeAgain}>
                      Modificar
                    </button>
                  </div>
                  <div className='col pl-lg-2 pr-0 pl-2'>
                    <button className='btn btn-primary' onClick={() => saveImage(setPreviewBack)}>
                      Guardar
                    </button>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default PictureBack
