import React from 'react'
import { Modal } from 'react-bootstrap'

import Error from 'assets/light/error.svg'
import ImageError from 'components/atoms/ImageError'
import './error.scss'

export default function ErrorSession({ modals, setModals }) {
  return (
    <>
      <Modal
        show={modals.errCode}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        contentClassName='text-center borderadius'
      >
        <ImageError image={Error} alt='imagen mensaje de error' style={{ width: '106px' }} />
        <p className='text-center pt-4 mb-0'>¡Código de Verificación Incorrecto!</p>
        <div className='button__primary pt-lg-5 p-4'>
          <div className='px-lg-5 mx-lg-5'>
            <button onClick={() => setModals({ ...modals, errCode: false })} className='btn btn-block btn-primary'>
              Intenta nuevamente
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
