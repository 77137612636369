import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import Family from 'assets/light/family.svg'
import Header from 'components/atoms/Header/Header'
import ImageHero from 'components/atoms/ImageHero'
import { AppContext } from 'context/AppContext'
import { effectDosesOne } from 'utils/initialState'

const FinalSide = () => {
  const { setGetUrl, setSideEffectDoses, setStep } = useContext(AppContext)

  const backHome = () => {
    setGetUrl('')
    setSideEffectDoses(effectDosesOne)
    setStep(1)
  }

  return (
    <>
      <Header />
      <div className='pt-3 pt-lg-0'></div>
      <h2 className='text-center header__title pb-lg-3 mb-lg-0'>¡Gracias por tu compromiso!</h2>

      <p className='text-center px-5 message__final mb-lg-3'>
        Recuerda que la salud depende de todos <br />
        <span className='message__loading'>#NoBajesLaGuardia</span>
      </p>

      <div className='pb-2 pb-lg-3'></div>
      <ImageHero image={Family} alt='imagen de familia' />

      <div className='pt-4 pb-3 pt-lg-3'></div>
      <h6 className='text-center message__info pb-lg-2'>Más información</h6>
      <p className='text-center message__country'>Seleccione según su país</p>
      <div className='py-2'></div>

      <div className='pt-lg-2 pt-4'>
        <div className='row px-4 px-lg-5 mx-lg-5'>
          <div className='col-sm-12 col-lg-6 pr-lg-2 pl-lg-0'>
            <div className='button__primary' style={{ paddingTop: 0 }}>
              <Link
                to={{ pathname: 'https://www.minsa.gob.pe/vacuna-covid-19/' }}
                target='_blank'
                className='btn btn-block btn-primary'
              >
                Perú
              </Link>
            </div>
            <div className='mb-2'></div>
          </div>
          <div className='col-sm-12 col-lg-6 pl-lg-2 pr-lg-0'>
            <div className='button__primary' style={{ paddingTop: 0 }}>
              <Link
                to={{ pathname: 'https://mivacuna.sispro.gov.co/MiVacuna/' }}
                target='_blank'
                className='btn btn-block btn-primary'
              >
                Colombia
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='text-center pt-2'>
        <b>
          <Link to='/home' onClick={backHome}>
            Volver al menú principal
          </Link>
        </b>
      </div>
    </>
  )
}

export default FinalSide
