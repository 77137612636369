import React from 'react'
import Logo from 'assets/logo_auna.svg'
import './Header.scss'

const Header = () => (
  <div className='header__logo text-center'>
    <img className='logo' src={Logo} alt='auna logo' />
  </div>
)

export default Header
