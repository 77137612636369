import { createContext, useState } from 'react'
import { users, triage, effectDosesOne } from '../utils/initialState'

export const AppContext = createContext()

export const ContextProvider = ({ children }) => {
  const [user, setUser] = useState(users)
  const [userBirthDate, setUserBithDate] = useState('')
  const [isAuth, setIsAuth] = useState(false)
  const [phoneAuth, setPhoneAuth] = useState({})
  const [step, setStep] = useState(1)
  const [userData, setUserData] = useState(triage)
  const [storeData, setStoreData] = useState({})
  const [dosesOne, setDosesOne] = useState()
  const [dosesTwo, setDosesTwo] = useState()
  const [previewFrontal, setPreviewFrontal] = useState('')
  const [previewBack, setPreviewBack] = useState('')
  const [sideEffectDoses, setSideEffectDoses] = useState(effectDosesOne)
  const [historySideEffects, setHistorySideEffects] = useState([])
  const [getUrl, setGetUrl] = useState('')

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target
    const onChange = type === 'checkbox' ? checked : value

    setUserData({
      ...userData,
      [name]: onChange,
    })
  }

  const handleEffects = (e) => {
    setSideEffectDoses({
      ...sideEffectDoses,
      [e.target.name]: e.target.checked,
    })
  }

  const prop = {
    isAuth,
    setIsAuth,
    phoneAuth,
    setPhoneAuth,
    step,
    setStep,
    userData,
    userBirthDate,
    setUserBithDate,
    setUserData,
    storeData,
    setStoreData,
    handleChange,
    user,
    setUser,
    dosesOne,
    setDosesOne,
    dosesTwo,
    setDosesTwo,
    sideEffectDoses,
    setSideEffectDoses,
    handleEffects,
    historySideEffects,
    setHistorySideEffects,
    previewFrontal,
    setPreviewFrontal,
    previewBack,
    setPreviewBack,
    getUrl,
    setGetUrl,
  }

  return <AppContext.Provider value={prop}>{children}</AppContext.Provider>
}
