import { Route } from 'react-router-dom'

import Vacunation from 'components/templates/Vaccine/Vacunation'
import PictureRecommendations from 'components/templates/Vaccine/Picture/PictureRecommendations'
import VaccineRegister from 'components/templates/Vaccine/VaccineRegister'
import VaccineTwoRegister from 'components/templates/Vaccine/VaccineTwoRegister'
import SelectDoses from 'components/templates/Vaccine/SelectDoses'
import DosesInfoOne from 'components/templates/Vaccine/DosesInfoOne'
import DosesInfoTwo from 'components/templates/Vaccine/DosesInfoTwo'
import SelectPictureViewOne from 'components/templates/Vaccine/Picture/SelectPictureViewOne'
import SelectPictureViewTwo from 'components/templates/Vaccine/Picture/SelectPictureViewTwo'
import PictureFrontalOne from 'components/templates/Vaccine/Picture/PictureFrontOne'
import PictureBackOne from 'components/templates/Vaccine/Picture/PictureBackOne'
import PictureFrontalTwo from 'components/templates/Vaccine/Picture/PictureFrontTwo'
import PictureBackTwo from 'components/templates/Vaccine/Picture/PictureBackTwo'
import ViewFrontOne from 'components/templates/Vaccine/Picture/ViewFrontOne'
import ViewBackOne from 'components/templates/Vaccine/Picture/ViewBackOne'
import ViewFrontTwo from 'components/templates/Vaccine/Picture/ViewFrontTwo'
import ViewBackTwo from 'components/templates/Vaccine/Picture/ViewBackTwo'
import FinalVaccine from 'components/templates/Final/FinalVaccine'

const VaccineRoute = () => (
  <>
    <Route exact path='/dosis' component={SelectDoses} />
    <Route exact path='/vacunacion' component={Vacunation} />
    <Route exact path='/recomendaciones-foto' component={PictureRecommendations} />
    <Route exact path='/foto-dosis-uno' component={SelectPictureViewOne} />
    <Route exact path='/previsualizar-frontal-uno' component={PictureFrontalOne} />
    <Route exact path='/previsualizar-reverso-uno' component={PictureBackOne} />
    <Route exact path='/foto-dosis-dos' component={SelectPictureViewTwo} />
    <Route exact path='/previsualizar-frontal-dos' component={PictureFrontalTwo} />
    <Route exact path='/previsualizar-reverso-dos' component={PictureBackTwo} />

    <Route exact path='/foto-frontal-dosis-uno' component={ViewFrontOne} />
    <Route exact path='/foto-reverso-dosis-uno' component={ViewBackOne} />
    <Route exact path='/foto-frontal-dosis-dos' component={ViewFrontTwo} />
    <Route exact path='/foto-reverso-dosis-dos' component={ViewBackTwo} />

    <Route exact path='/dosis-uno' component={VaccineRegister} />
    <Route exact path='/dosis-dos' component={VaccineTwoRegister} />
    <Route exact path='/registro-vacuna-dosis-uno' component={DosesInfoOne} />
    <Route exact path='/registro-vacuna-dosis-dos' component={DosesInfoTwo} />
    <Route exact path='/finalizar-vacuna' component={FinalVaccine} />
  </>
)

export default VaccineRoute
