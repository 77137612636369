import React from 'react'
import './ButtonPrimary.scss'

function ButtonPrimaryPhone({ phone }) {
  return (
    <div className='button__primary pt-5 mt-lg-4'>
      <div className='px-4 px-5 mx-lg-5 mt-4 pt-lg-4'>
        <button className='btn btn-block btn-primary' type='submit' disabled={phone?.length > 1 ? false : true}>
          Siguiente
        </button>
      </div>
    </div>
  )
}

export default ButtonPrimaryPhone
