import React from 'react'

import Logo from 'assets/logo_auna.svg'

const LogoTerms = () => (
  <div className='text-center py-lg-4 my-lg-3'>
    <img className='d-none d-lg-inline' src={Logo} alt='Logo Auna' />
    <div className='pb-lg-3'></div>
  </div>
)

export default LogoTerms
