import { Route } from 'react-router-dom'

import Register from 'components/templates/Register/Register'
import TermsCountry from 'components/templates/Register/TermsCountry'
import PrivacyCountry from 'components/templates/Register/PrivacyCountry'
import TermsPeru from 'components/templates/Register/TermsPeru'
import TermsColombia from 'components/templates/Register/TermsColombia'
import PrivacyPeru from 'components/templates/Register/PrivacyPeru'
import PrivacyColombia from 'components/templates/Register/PrivacyColombia'
import UserProfile from 'components/templates/Profile/UserProfile'

const RegisterRoute = () => (
  <>
    <Route exact path='/registro' component={Register} />
    <Route exact path='/terminos-pais' component={TermsCountry} />
    <Route exact path='/privacidad-pais' component={PrivacyCountry} />
    {/* Legal Colombia */}
    <Route exact path='/terminos-colombia' component={TermsColombia} />
    <Route exact path='/privacidad-colombia' component={PrivacyColombia} />

    {/* Legal Peru */}
    <Route exact path='/privacidad-peru' component={PrivacyPeru} />
    <Route exact path='/terminos-peru' component={TermsPeru} />

    <Route exact path='/usuario' component={UserProfile} />
  </>
)

export default RegisterRoute
