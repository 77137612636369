import React from 'react'

import CardView from '../atoms/CardView/CardView'

const RecommendationsCard = ({ userData, age }) => {
  const { diabetes, pregnancy, hypertension, pulmonary, renal, immunosuppressive, essentialJob, bmi } = userData

  return (
    <>
      {essentialJob && <CardView title='Trabajador Esencial' route='esencial' />}
      {pregnancy && <CardView title='Embarazo, Lactacia' route='embarazo' />}
      {age <= 18 && <CardView title='Menor o igual a 18 años' route='menor' />}
      {bmi >= 30 && <CardView title='Índice masa corporal' route='corporal' />}
      {age > 18 && age < 60 && <CardView title='Entre 18 y 59 años' route='adulto' />}
      {age >= 60 && <CardView title='Mayor o igual a 60 años' route='mayor' />}
      {immunosuppressive && <CardView title='Personas inmunodeprimidas ' route='inmunosupresor' />}
      {hypertension && <CardView title='Hipertensión arterial Patologías cardíacas' route='hipertension' />}
      {pulmonary && <CardView title='EPOC' route='epoc' />}
      {diabetes && <CardView title='Diabetes' route='diabetes' />}
      {renal && <CardView title='Enfermedades renales' route='renal' />}
    </>
  )
}

export default RecommendationsCard
