import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

import firebase from 'utils/firebaseConfig'
import ImageHero from 'components/atoms/ImageHero'
import Heart from 'assets/light/heart.svg'
import CardInfo from 'components/atoms/CardInfo/CardInfo'
import CardFase from 'components/atoms/CardFase/CardFase'
import RecommendationsCard from 'components/molecules/RecommendationsCard'
import { AppContext } from 'context/AppContext'
import { triage } from 'utils/initialState'

const Calculator = () => {
  const { userData, user, setUserData } = useContext(AppContext)
  const { age } = user

  useEffect(() => {
    firebase.analytics().logEvent('resultados-calculadora')
  })

  return (
    <>
      <div className='px-4 pt-1 px-lg-5 mx-lg-5'>
        <div className='pt-4 pb-3 mt-2'>
          <ImageHero image={Heart} style={{ maxHeight: '298' }} alt='imagen de un corazón' />
        </div>

        <h4 className='py-2 text-center pb-lg-3 header__title'>Fase de vacunación</h4>

        {userData && <CardFase userData={userData} age={age} />}
        <h5 className='text__subtitle pt-4 mt-2 pb-2 mt-lg-0'>Recomendaciones</h5>
        <div className='pb-2'></div>

        {userData && <RecommendationsCard userData={userData} age={age} />}

        <CardInfo description='Recuerda que la salud depende de todos #NoBajemosLaGuardia' />
      </div>

      <div className='button__primary pt-lg-5'>
        <hr className='mb-2 d-lg-none' />
        <div className='px-4 mb-5 px-lg-5 mx-lg-5'>
          <Link
            className='btn btn-block btn-primary'
            to='/finalizar'
            onClick={() => {
              setUserData(triage)
            }}
          >
            Continuar
          </Link>
        </div>
      </div>
    </>
  )
}

export default Calculator
