import React from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import './error.scss'

export default function ErrorSession({ modals, setModals }) {
  const history = useHistory()
  return (
    <>
      <Modal
        show={modals.errSession}
        onHide={() => history.push('/home')}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        contentClassName='text-center borderadius'
      >
        <Modal.Header closeButton className='border-bottom-0 pb-0 button'></Modal.Header>

        <p className='text-center pt-4 mb-0'>¡Ha caducado su sesión!</p>
        <div className='button__primary pt-lg-5 p-4'>
          <div className='px-lg-5 mx-lg-5'>
            <button onClick={() => setModals({ ...modals, errSession: false })} className='btn btn-block btn-primary'>
              Intenta nuevamente
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
