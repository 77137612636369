import { useState, useEffect, useContext } from 'react'
import moment from 'moment'

import VaccineRegisterForm from 'components/templates/Vaccine/VaccineRegisterForm'
import VaccineConfirmationForm from 'components/templates/Vaccine/VaccineConfirmationForm'
import RetakePictureFront from './Picture/RetakePictureFront'
import RetakePictureBack from './Picture/RetakePictureBack'
import { vaccineTwo } from 'utils/initialState'
import { AppContext } from 'context/AppContext'
import firebase from 'utils/firebaseConfig'

function VaccineRegisterTwo() {
  const { step, dosesOne } = useContext(AppContext)
  const [vaccine, setVaccine] = useState(vaccineTwo)
  const [vaccinationDate, setVaccinationDate] = useState('')

  const onChange = (date) => {
    setVaccinationDate(date)
    setVaccine({ ...vaccine, vaccinationDate: moment(vaccinationDate).format('YYYY-MM-DD') })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setVaccine({ ...vaccine, [name]: value, manufacturer: dosesOne.manufacturer })
  }

  const props = { vaccine, vaccinationDate, handleChange, onChange }

  useEffect(() => {
    firebase.analytics().logEvent('registro-vacuna-dosis-dos')
  }, [])

  useEffect(() => {
    if (!vaccinationDate) return
    setVaccine({
      ...vaccine,
      vaccinationDate: moment(vaccinationDate).format('YYYY-MM-DD'),
      manufacturer: dosesOne.manufacturer,
    })
  }, [setVaccine, vaccinationDate, dosesOne])

  const stepUp = (step) => {
    switch (step) {
      case 1:
        return <VaccineRegisterForm {...props} text='Dosis 2' />

      case 2:
        return <VaccineConfirmationForm {...props} text='Dosis 2' />

      case 3:
        return <RetakePictureFront route='/foto-dosis-dos' />

      case 4:
        return <RetakePictureBack route='/foto-dosis-dos' />

      default:
        return <VaccineRegisterForm {...props} text='Dosis 2' />
    }
  }

  return <>{stepUp(step)}</>
}

export default VaccineRegisterTwo
