import { useState, useEffect, useContext } from 'react'
import Virus from 'assets/light/virus.svg'
import Header from 'components/atoms/Header/Header'
import ImageHero from 'components/atoms/ImageHero'
import Paragraph from 'components/atoms/Paragraph/Paragraph'
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary'
import { content } from 'utils/content'
import { request } from 'services/requestService'
import { AppContext } from 'context/AppContext'

function ReactionsOnboarding() {
  const { title, paragraph, button } = content[6]
  const [dosesApplied, setDosesApplied] = useState([])
  const { setDosesOne, setDosesTwo, getUrl } = useContext(AppContext)

  // This function indexed the array for each doses to make a better selection and set in state
  const indexado = dosesApplied.reduce(
    (acc, el) => ({
      ...acc,
      [el.doses]: el,
    }),
    {}
  )

  useEffect(() => {
    if (getUrl === 'sms') {
      request('get', 'vaccine').then(({ data }) => {
        setDosesApplied(data)
      })
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    // Here we set the index into global state to call everywhere into the app
    if (getUrl === 'sms') {
      setDosesOne(indexado[1])
      setDosesTwo(indexado[2])
    }
  }, [setDosesOne, setDosesTwo, indexado, getUrl])

  return (
    <div>
      <Header />
      <div className='pb-3'></div>
      <ImageHero image={Virus} alt='imagen de virus' />
      <Paragraph title={title} paragraph={paragraph} />
      <div className='paragraph text-center px-4 px-lg-5 mx-lg-5'>
        <p className='text-center mb-1'>
          Queremos identificar las reacciones adversas de la vacuna contra el COVID-19.
        </p>
      </div>

      <div className='py-lg-0 py-5'></div>
      <div className='d-none d-lg-block'>
        <ButtonPrimary button={button} route='/tuviste-reaccion-adversa' />
      </div>
      <div className='fixed-bottom d-lg-none'>
        <ButtonPrimary button={button} route='/tuviste-reaccion-adversa' padding='0' />
      </div>
    </div>
  )
}

export default ReactionsOnboarding
