import React from 'react'

const ButtonRegister = ({ user, dateValidation }) => (
  <>
    <div className='pb-lg-1'></div>
    <div className='button__primary pt-lg-5'>
      <hr className='mb-2 d-lg-none' />
      <div className='px-4 px-lg-5 mx-lg-5'>
        <button
          className='btn btn-block btn-primary'
          disabled={!user.termsAccepted}
          type='submit'
          onClick={dateValidation}
        >
          Registrarse
        </button>
      </div>
    </div>
  </>
)

export default ButtonRegister
