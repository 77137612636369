import React from 'react'
import countries from 'utils/countriesList'

function SelectCountry({ selectRef, selectCountryHandler, countryCodeValue }) {
  return (
    <>
      <select className='login__select' ref={selectRef} onChange={selectCountryHandler} defaultValue={countryCodeValue}>
        {countries.map(({ code, dial_code, name }) => (
          <option key={code} data-countrycode={code} value={dial_code}>
            {name} ({dial_code})
          </option>
        ))}
      </select>
    </>
  )
}

export default SelectCountry
