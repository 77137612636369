import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import ArrowBack from 'assets/icons/arrowLeft.svg'
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary'
import LogoTerms from 'components/atoms/LogoTerms'
import firebase from 'utils/firebaseConfig'

function TermsColombia() {
  useEffect(() => {
    firebase.analytics().logEvent('terminos-condiciones-peru')
  }, [])

  return (
    <>
      <div className='px-4 pt-3 px-lg-0'>
        <LogoTerms />
        <div className='row px-3 pb-4 d-flex align-items-center mb-3'>
          <div className='col-2 ml-0 pl-0 d-lg-none px-0'>
            <Link to='/registro'>
              <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
            </Link>
          </div>
          <div className='col-8 col-lg-12 conditions__title px-0'>
            <h5 className='text-center mb-0'>Términos y condiciones Colombia</h5>
          </div>
        </div>
        <div className='conditions__container pt-lg-3'>
          <div className='message__conditions'>
            <p className='text-center'>(Última actualización: 21/04/2021)</p>
            <span>
              <strong>A. GENERALIDADES</strong>
            </span>
            <p>
              AUNA-VACUNATE (en adelante, LA PLATAFORMA) es una plataforma virtual en las modalidades web y aplicación
              que busca identificar los factores de riesgo de producir enfermedad severa e informar sobre medidas de
              control para la prevención de COVID19, así como generar un repositorio de certificados de vacunación a
              servicio de cada persona que decida utilizarlo. Se trata de un esfuerzo de PROMOTORA MEDICA LAS AMERICAS
              S.A. (en adelante, AUNA).
            </p>
            <p>
              Para ello, AUNA-VACUNATE permite a los Usuarios, con la información que cada uno de ello brinde (i)
              conocer la fase de vacunación que les corresponde, de acuerdo a lo expuesto por el Ministerio de Salud y
              Seguridad Social de Colombia y a sus antecedentes médicos; (ii) conocer la evolución de su proceso post
              vacunación, en caso ya hayan sido inoculados, para lo cual analizaremos la evolución de su salud y
              sintomatología, también considerando sus antecedentes médicos y, de encontrar anomalías en dicho proceso,
              se le sugerirá consultar a un médico; y; (iii) contar con un repositorio digital para su certificado de
              vacunación. No se brindará servicio médico alguno adicional a través de AUNA-VACUNATE.
            </p>
            <p>El acceso y uso de AUNA-VACUNATE es totalmente gratuito.</p>

            <span>
              <strong>1. Tu relación con LA PLATAFORMA:</strong>
            </span>
            <p>
              1.1 El uso de LA PLATAFORMA y todo lo que ello involucre estará sujeto a las condiciones de un acuerdo
              legal y vinculante entre Ud. y AUNA; por lo que es importante que los lea detenidamente.
            </p>
            <p>
              1.2 Ud. se obliga a no distribuir ningún Contenido, imagen, look&feel o tecnologías relacionadas, de LA
              PLATAFORMA sin la previa autorización por escrito de AUNA.
            </p>

            <span>
              <strong>2. Cambios en los Términos y Condiciones</strong>
            </span>
            <p>
              2.1 AUNA se reserva el derecho a realizar modificaciones en estos Términos y Condiciones en cualquier
              momento, como consecuencia de cambios de ley o normativos en general, así como por cualquier otra razón.
            </p>
            <p>
              2.2 Las modificaciones serán visualizadas a través de la publicación en LA PLATAFORMA, por lo que el
              Usuario podrá tomar conocimiento en la primera oportunidad que ingrese y desee utilizarlos. Si luego de la
              modificación Ud. no está de acuerdo con los Términos y Condiciones, dentro de los 5 (cinco) días
              calendario siguientes a su publicación, deberá contactarse con atención al cliente a fin de que el vínculo
              contractual quede disuelto. Vencido este plazo, si no existe oposición de su parte o continúa utilizando
              LA PLATAFORMA, se considerará que Ud. acepta los nuevos términos y el contrato continuará vinculando a
              ambas partes.
            </p>
            <p>
              2.3 Las modificaciones en las funcionalidades ofrecidas por LA PLATAFORMA en atención a la necesidad
              innovar y mejorar su experiencia y la usabilidad de LA PLATAFORMA, pueden producirse en cualquier momento
              sin necesidad de preaviso; y, no se sujetan a las formalidades del Numeral 2.2. previo. La misma regla se
              aplica para cualquier modificación que Ud. pueda advertir con tan solo visitar o interactuar en LA
              PLATAFORMA.
            </p>
            <span>
              <strong>3. Cuentas de LA PLATAFORMA</strong>
            </span>
            <p>
              3.1 Al crear su cuenta, Ud. deberá facilitar determinada información correcta y completa, cuyo tratamiento
              se rige por el presente documento, así como por nuestra Política de Privacidad publicada en
              www.lasamericas.com.co.
            </p>
            <p>
              3.2 Para acceder y utilizar LA PLATAFORMA, Ud. dispondrá de una clave personal. Esta clave será
              determinada por Ud. y deberá mantenerla bajo absoluta confidencialidad y, en ningún caso, deberá revelarla
              o compartirla con otras personas.
            </p>
            <p>
              3.3 Ud. Ud. será responsable único de todos los actos que tengan lugar mediante el uso de dirección de
              correo electrónico y Clave Personal de acceso a LA PLATAFORMA, lo que incluye responsabilizarse por los
              perjuicios que eventualmente puedan sufrir otros usuarios, terceros o LA PLATAFORMA por tal motivo. Si por
              cualquier razón Ud. tuviera sospechas de que alguien puede conocer su Clave Personal, deberá modificarla
              de inmediato y sin dilación.
            </p>
            <p>
              3.4 Ud. es el único responsable del uso de su cuenta, usuario y clave. Deberá notificar a AUNA cualquier
              uso o riesgo de uso no autorizado de su cuenta en LA PLATAFORMA, del que tenga conocimiento inmediatamente
              después de haberlo conocido.
            </p>
            <span>
              <strong> 4. Restricciones generales a la utilización</strong>
            </span>
            <p>
              4.1 Ud. puede acceder y utilizar LA PLATAFORMA, con sujeción a las condiciones específicas que se indican
              en estos Términos y Condiciones, cuyo cumplimiento es obligatorio.
            </p>
            <p>
              4.2 El uso de LA PLATAFORMA debe realizarse conforme las leyes y reglamentos vigentes en toda la República
              del Perú, así como con los tratados internacionales pertinentes.
            </p>
            <p>
              4.3 Ud. se obliga a no distribuir ningún Contenido, imagen, look&feel o tecnologías relacionadas, de LA
              PLATAFORMA sin la previa autorización por escrito de AUNA.
            </p>
            <p>
              4.4 Ud. se obliga a no alterar o modificar en todo o parte LA PLATAFORMA incluyendo -pero sin limitarse- a
              sus tecnologías o contenidos.
            </p>
            <p>
              4.5 Ud. se obliga a no eludir, desactivar o manipular de cualquier otra forma (o tratar de eludir,
              desactivar o manipular) las funciones de seguridad de los servicios u otras funciones que (i) impidan o
              restrinjan la utilización o copia de los Contenidos, o (ii) que aplican las limitaciones a la utilización
              de los servicios o de los Contenidos que se ofrecen a través de los servicios. El incumplimiento de esta
              obligación puede desencadenar consecuencias en el ámbito del Derecho Penal. AUNA se reserva el derecho de
              denunciar penalmente a Ud. y/o a los responsables por el incumplimiento de estas obligaciones, sin
              perjuicio de las denuncias ante las autoridades administrativa que correspondan.
            </p>
            <p>
              4.6 Ud. se obliga a no utilizar LA PLATAFORMA para difundir cadenas, spam, virales o cualquier otro tipo
              de Contenido que no sea adecuado a la naturaleza y finalidad de LA PLATAFORMA.
            </p>
            <p>
              4.7 Ud. se obliga a no utilizar o activar ningún sistema automatizado o lector offline que acceda al
              servicio a fin de enviar más mensajes de solicitud de los que en un intervalo de tiempo una persona física
              podría razonablemente producir utilizando un navegador web públicamente disponible y general (es decir,
              sin modificar).
            </p>
            <p>
              4.8 Ud. se obliga a no recabar datos personales de ningún usuario de LA PLATAFORMA o los servicios
              (incluyendo los nombres de cuenta de LA PLATAFORMA, dirección de correo electrónico, entre otros).
            </p>
            <p>
              4.9 Conforme a las normas sobre Derecho de Autor, Ud. no deberá copiar, reproducir, distribuir,
              transmitir, transformar, modificar, emitir o comunicar al público, exponer, vender, licenciar, o de
              cualquier otra forma explotar bajo cualquier modalidad, los Contenido o tecnologías de LA PLATAFORMA, para
              cualquier finalidad (comercial o no comercial, con fines lucrativos o no) sin el consentimiento previo y
              por escrito de AUNA o los licenciantes respectivos del Contenido. Asimismo, Ud. reconoce la titularidad de
              AUNA respecto de las marcas y logos que se encuentren en LA PLATAFORMA. En consecuencia, Ud. reconoce que
              no tiene capacidad legal de utilizar dichos signos distintivos sin la previa autorización de su titular.
              El incumplimiento de las obligaciones previstas en esta cláusula, puede desencadenar consecuencias en el
              ámbito del Derecho Penal y Administrativo. AUNA se reserva el derecho de denunciar penalmente y
              administrativamente a Ud. y/o a los responsables por el incumplimiento de estas obligaciones, sin
              perjuicio de las denuncias ante las autoridades administrativa que correspondan.
            </p>
            <p>
              4.10 Como parte del proceso de innovación constante, Ud. reconoce y acepta que AUNA podrá interrumpir (de
              forma permanente o temporal) la prestación de los servicios (o de cualquier función de estos) a Ud. o a
              los usuarios en general, como consecuencia de requisitos operacionales tales como aquellos relacionados
              con la seguridad de los servicios, su seguridad como usuario, o a necesidades de mantenimiento de los
              servicios. Ud. podrá dejar de utilizar los servicios en cualquier momento sin estar obligado a informar de
              ello a AUNA.
            </p>
            <p>
              4.11 Ud. reconoce ser el único responsable (sin que AUNA asuma responsabilidad alguna frente a Ud. o a
              cualquier tercero) del incumplimiento de sus obligaciones previstas en estos Términos y Condiciones y de
              las consecuencias (incluyendo cualesquiera pérdidas o daños en que AUNA pueda incurrir) derivadas de dicho
              incumplimiento.
            </p>
            <span>
              <strong>5. Finalización de su relación con AUNA a través de LA PLATAFORMA:</strong>
            </span>
            <p>
              5.1 Si desea resolver el presente contrato con AUNA, podrá hacerlo (a) notificándolo en cualquier momento,
              por escrito, a la dirección de AUNA indicada al inicio de estos Términos y Condiciones; o, (b) dándose de
              baja o cerrando su cuenta.
            </p>
            <p>
              5.2 AUNA podrá resolver el presente contrato y darle de baja en LA PLATAFORMA en cualquier momento si:
            </p>
            <p>
              - Ud. incumple cualquiera de las disposiciones incluidas en estos Términos y Condiciones, o actúa de forma
              tal que se pueda inferir que pretende hacerlo o que no será capaz de hacerlo. <br />
              <br />
              - Por mandato de la autoridad administrativa, policial o judicial competente. <br />
              <br />
              - Deja de prestar los servicios, se elimina o se da de baja de LA PLATAFORMA. <br />
              <br />- La prestación de los servicios a Ud. por parte de AUNA, deja de ser en opinión de ésta última,
              comercialmente viable.
            </p>
            <p>
              5.3 La resolución del contrato por parte de AUNA surte efecto inmediato y sin otra formalidad que la
              notificación de dicha resolución y su motivo a la dirección de correo electrónico vinculada a su cuenta.
            </p>
            <p>
              5.4 La resolución del contrato no afecta los derechos y obligaciones legales que correspondan tanto a Ud.
              como a AUNA (o que se hayan devengado durante la vigencia de estos Términos y Condiciones), o cuya
              vigencia deba continuar conforme a su naturaleza, condición, características o acuerdos (incluyendo los
              contenidos en estos Términos y Condiciones).
            </p>
            <span>
              <strong>6. Disputas entre Usuarios</strong>
            </span>
            <p>
              6.1 LA PLATAFORMA permitirá la interacción entre Usuarios, siendo cada uno de ellos único y total
              responsable de dicha interacción. Ante ello, Ud. es el único responsable de las interacciones con otros
              usuarios de LA PLATAFORMA, así como de sus eventuales consecuencias.
            </p>
            <p>
              6.2 AUNA se reserva el derecho, pero no tiene la obligación, de mediar entre las disputas de los usuarios.
            </p>
            <p>
              6.3 De ser el caso, AUNA se reserva el derecho de eliminar cuentas o bloquear accesos a usuarios cuya
              conducta no sea considerada apropiada o presente visos de discriminación, apología de terrorismo,
              violencia, injuria, difamación, xenofobia y otras acciones contrarias al orden público o que puedan
              socavar las bases de una convivencia social armónica en el marco de un Estado Social de Derecho.
            </p>
            <span>
              <strong>B. FUNCIONALIDAD DE LA PLATAFORMA</strong>
            </span>
            <p>
              7. Registro: Al crear una cuenta en LA PLATAFORMA, si el usuario ya es paciente de AUNA o de una de las
              empresas y clínicas que conforman el Grupo Auna, o Las Américas Auna las cuales se encuentran listadas en
              www.lasamericas.com.co se vinculará su historia clínica con su usuario de LA PLATAFORMA, para lo cual
              usted deberá autorizar en forma expresa. Si el usuario no es paciente, se le creará en automático una
              historia clínica que será vinculada a la cuenta creada.
            </p>
            <p>
              8. Activación de cuenta: El usuario recibirá un código de activación al correo indicado para poder activar
              la cuenta a fin de poder acceder a LA PLATAFORMA.
            </p>
            <p>9. Login: El usuario podrá logearse con su usuario (correo) registrado previamente y contraseña. </p>
            <p>
              10. Recuperación de contraseña: El usuario podrá indicar que olvidó su contraseña para que se le envíe un
              mensaje a su correo registrado con un código para generar una nueva contraseña.
            </p>
            <p>
              11. Servicios disponibles: AUNA-VACUNATE permite a los Usuarios, con la información que cada uno de ellos
              brinde, lo siguiente:
            </p>
            <p>
              a) Conocer la fase de vacunación que les corresponde, de acuerdo a lo expuesto por el Ministerio de Salud
              y Seguridad Social de Colombia y a sus antecedentes médicos; <br />
              <br />
              b) Conocer la evolución de su proceso post vacunación, en caso ya hayan sido inoculados, para lo cual
              analizaremos la evolución de su salud y sintomatología, también considerando sus antecedentes médicos. De
              encontrar anomalías en dicho proceso, se le sugerirá consultar a un médico; y,
              <br />
              <br />
              c) Contar con un repositorio digital para su certificado de vacunación, el que podrá ser descargado en
              cualquier momento únicamente a través de la cuenta del Usuario, para los fines que considere pertinentes.
            </p>
            <p>
              No se brindará servicio médico alguno adicional a través de AUNA-VACUNATE. Asimismo, no se derivará
              pacientes a centros médicos específicos, ni se asegurará la disponibilidad de medicamentos, cuartos en
              clínicas, hospitales, ni tampoco espacio para la atención de pacientes Covid-19 en unidades de cuidados
              intensivos de cualquier nosocomio.
            </p>
            <p>
              12. Atención al Cliente: Para cualquier consulta sobre los servicios de LA PLATAFORMA, o información
              adicional sobre los servicios que ofrece el AUNA, puede comunicarse enviar un correo electrónico a
              protecciondatospersonales@lasasmericas.com.co.
            </p>
            <p>
              13. Atención de Reclamos: Si usted desea presentar un reclamo en relación al servicio brindado por LA
              PLATAFORMA, lo puede realizar a través del correo protecciondatos personales@lasamericas.com.co o, de
              considerarlo necesario, en el Libro de Reclamaciones Virtual de LA PLATAFORMA.
            </p>
            <p>
              14. Estos Términos y Condiciones y su relación con AUNA conforme a lo aquí previsto, se regirán por las
              leyes de Colombia y cualquier controversia será sometida a la jurisdicción ordinaria colombiana.
            </p>
          </div>
        </div>
      </div>
      <div className='p-lg-3'></div>

      <div className='d-none d-lg-block px-5'>
        <div className='mt-lg-2'></div>
        <div className='mx-5'>
          <ButtonPrimary button='Volver' route='/registro' />
        </div>
      </div>
    </>
  )
}

export default TermsColombia
