import { useContext } from 'react'
import { Link } from 'react-router-dom'

import ArrowBack from 'assets/icons/arrowLeft.svg'
import LogoTerms from 'components/atoms/LogoTerms'
import { AppContext } from 'context/AppContext'

function ViewFrontOne() {
  const { dosesOne } = useContext(AppContext)

  return (
    <>
      <div className='pt-lg-3'></div>
      <LogoTerms />
      <div className='px-4 pt-3 pt-lg-0'>
        <div className='row px-3 pb-3 d-flex align-items-center mb-lg-3'>
          <div className='col-2 ml-0 pl-0 d-lg-none'>
            <Link to='/registro-vacuna-dosis-uno'>
              <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
            </Link>
          </div>
          <div className='col-8 col-lg-12 conditions__title'>
            <h5 className='text-center mb-0'>Frente de la cartilla</h5>
          </div>
        </div>

        <div className='d-none d-lg-block text-center image-input'>
          <div className='ani-show'>
            <img src={dosesOne?.imgFront} alt='foto frontal imagen' />
          </div>
        </div>

        <div className='d-lg-none text-center px-4 image-input'>
          <div className='ani-show'>
            <img src={dosesOne?.imgFront} alt='foto frontal imagen' />
          </div>
        </div>

        <div className='py-4 mt-3 d-none d-lg-block px-4 mx-lg-5'>
          <div className='mt-lg-1'></div>
          <div className='px-lg-0 d-none d-lg-block'>
            <Link to='/registro-vacuna-dosis-uno' className='btn btn-outline-secondary'>
              Atrás
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewFrontOne
