import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import ArrowBack from 'assets/icons/arrowLeft.svg'
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary'
import LogoTerms from 'components/atoms/LogoTerms'
import firebase from 'utils/firebaseConfig'

function PrivacyPeru() {
  useEffect(() => {
    firebase.analytics().logEvent('politica-privacidad-peru')
  }, [])

  return (
    <>
      <div className='px-4 pt-3 px-lg-0'>
        <LogoTerms />
        <div className='row px-3 pb-4 d-flex align-items-center mb-3'>
          <div className='col-2 ml-0 pl-0 d-lg-none px-0'>
            <Link to='/registro'>
              <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
            </Link>
          </div>
          <div className='col-8 col-lg-12 conditions__title px-0'>
            <h5 className='text-center mb-0'>Políticas de privacidad Perú</h5>
          </div>
        </div>
        <div className='conditions__container pt-lg-3'>
          <div className='message__conditions'>
            <span>
              <strong>1. Objetivo</strong>
            </span>
            <p>
              La presente política de privacidad de Datos Personales (la “Política de Privacidad”), tiene por finalidad
              informarle cómo MEDIC SER S.A.C. (en adelante, “AUNA”) realiza el tratamiento de los Datos Personales que
              recopila mediante el uso del AUNA Vacunas, BOT AUNA y otros dispositivos con fines similares o
              complementarios (“los dispositivos”). mismas.
            </p>
            <p>
              Para estos efectos, haremos referencia al término “Usuarios”, para referirnos a: i) las personas que
              utilizan los dispositivos para acceder a información relacionada con el COVID-19; ii) recibir
              recomendaciones relacionadas a hábitos de salud para prevenir el virus; iii) recibir información relevante
              relacionada con las vacunas y el proceso de vacunación de COVID-19; iii) reportar su estado de salud con
              el fin determinar si es posible que, por los síntomas descritos, haya contraído el virus y recibir por
              parte de AUNA recomendaciones sobre cómo proceder para ser atendido por las autoridades competentes, de
              ser el caso; y, iv) recibir información relacionada con la vacuna contra el COVID-19, las fases de
              vacunación, reportar posibles efectos adversos de la vacuna y asesoría por parte de los profesionales de
              la salud de AUNA. En AUNA aseguramos la máxima seguridad y protección de los Datos Personales de
              titularidad de nuestros Usuarios.
            </p>
            <p>
              Le agradecemos leer esta Política de Privacidad antes de proporcionarnos sus datos personales. Estos datos
              son necesarios para cumplir con las finalidades descritas en la presente Política de Privacidad por lo
              que, al no permitir su tratamiento, impediría estar en condiciones para cumplir las mismas.
            </p>
            <span>
              <strong>2. Alcance y plazo de conservación</strong>
            </span>
            <p>
              Esta Política de Privacidad se aplicará al banco de datos personales denominado “COVID-19”, cuyo titular
              es AUNA (Medic Ser S.A.C.), en el que los datos personales proporcionados se conservan mientras no se
              solicite su cancelación por el titular del dato.
            </p>
            <span>
              <strong>3. Marco normativo y principios rectores</strong>
            </span>
            <p>Esta política se encuentra regulada por la legislación peruana y en particular por:</p>
            <p>
              ● Ley No. 29733 (la “Ley”). <br />
              <br />● Decreto Supremo No. 003-2013-JUS, que aprueba el Reglamento de la Ley No. 29733 (el “Reglamento”).
              <br />
              <br />
              ● Directiva de Seguridad de la Información, aprobada por la Resolución Directoral No. 019-2013-JUS/DGPDP
              (la “Directiva”).
              <br />
              <br /> ● Ley No. 29571, Código de Protección y Defensa del Consumidor. AUNA desarrolla su Política
              Privacidad en el tratamiento de Datos Personales en atención a los principios rectores establecidos en la
              Ley y el Reglamento, por lo tanto:
            </p>
            <p>
              1. De acuerdo al principio de legalidad, AUNA rechaza la recopilación de los Datos Personales de nuestros
              Usuarios por medios fraudulentos, desleales o ilícitos. <br />
              <br />
              2. Conforme al principio de consentimiento, en el tratamiento de los Datos Personales de nuestros Usuarios
              debe mediar su consentimiento. Sin embargo, en el caso particular, comunicamos a nuestros Usuarios que sus
              datos están siendo recopilados en el marco de la Emergencia Sanitaria declarada por el Decreto Supremo Nº
              008-2020-SA y el Estado de Emergencia Nacional declarado por el Decreto Supremo 044-2020-PCM; ello, en el
              marco de la Pandemia provocada por el COVID-19, por lo cual su tratamiento se realiza conforme al numeral
              6 del artículo 14 de la Ley de Protección de Datos Personales. <br />
              <br />
              3. Los Datos Personales de nuestros Usuarios se recopilan para una finalidad determinada, explícita y
              lícita, y no se extenderá a otra finalidad que no haya sido la establecida de manera inequívoca al momento
              de su recopilación, excluyendo los casos de actividades de valor histórico, estadístico o científico
              cuando se utilice un procedimiento de disociación o anonimización. <br />
              <br />
              4. Todo tratamiento de Datos Personales será adecuado, relevante y no excesivo respecto de la finalidad
              para la cual fueron recopilados.
              <br />
              <br />
              5. Los Datos Personales que vayan a ser tratados serán veraces, exactos y, en la medida de lo posible,
              actualizados, necesarios, pertinentes y adecuados respecto de la finalidad para la que fueron recopilados.
              Se conservarán de forma tal que se garantice su seguridad y sólo por el tiempo necesario para cumplir con
              la finalidad del tratamiento. <br />
              <br />
              6. AUNA y los encargados de tratamiento, si hubieran, adoptan las medidas técnicas, organizativas y
              legales necesarias para garantizar la seguridad y confidencialidad de los Datos Personales. AUNA cuenta
              con las medidas de seguridad apropiadas, acorde con el tratamiento que se vaya a efectuar y con la
              categoría de Datos Personales que se trate.
              <br />
              <br />
              7. AUNA garantiza el nivel adecuado de protección de los Datos Personales para el flujo transfronterizo de
              Datos Personales, con un mínimo de protección equiparable a lo previsto por la Ley y el Reglamento.
            </p>
            <span>
              <strong>4. Definición de Datos Personales</strong>
            </span>
            <p>
              La Ley y el Reglamento definen Datos Personales como aquella información numérica, alfabética, gráfica,
              fotográfica, acústica, sobre hábitos personales o de cualquier otro tipo concerniente a las personas
              naturales que las identifica o las hace identificables a través de medios que puedan ser razonablemente
              utilizados (en adelante, “Datos Personales”).
            </p>
            <span>
              <strong>5. El banco de datos de titularidad de AUNA</strong>
            </span>
            <p>
              Un banco de Datos Personales es un conjunto organizado de Datos Personales, automatizado o no,
              independientemente del soporte, sea este físico, magnético, digital, óptico u otros que se creen,
              cualquiera fuera la norma o modalidad de su creación, formación, almacenamiento, organización y acceso.
            </p>
            <p>
              En el cumplimiento estricto de Ley y su Reglamento, los bancos de datos de AUNA que contienen Datos
              Personales de los Usuarios se encuentran inscritos en el Registro de Protección de Datos de la Autoridad
              de Protección de Datos Personales. Los Usuarios otorgan su consentimiento expreso para la inclusión de sus
              Datos Personales en el Banco de Datos denominado “COVID-19”, de titularidad de AUNA (Medic Ser S.A.C.).
            </p>
            <span>
              <strong>6. ¿Para qué utilizamos la información de los Usuarios?</strong>
            </span>
            <p>Los Datos Personales de los Usuarios se utilizan para los siguientes fines:</p>
            <p>
              1. Dependiendo del uso que haga el Usuario de los dispositivos: remitirle información de interés
              relacionada al COVID-19, así como cifras y estadísticas actuales sobre el estado de la Pandemia; acceder a
              contenido de AUNA relacionado con el COVID-19; recibir tips y consejos sobre prevención del virus;
              determinar, de acuerdo a los síntomas descritos, la probabilidad que usted haya contraído el COVID-19, así
              como brindarle recomendaciones sobre cómo proceder para ser atendido por las autoridades competentes, de
              ser el caso; acceder a información relacionada con la vacuna y las fases de vacunación; determinar, en
              base a información proporcionada por el usuario, la fase en la cual podría recibir la vacuna; reportar
              síntomas asociados a posibles efectos adversos de la vacuna y recibir seguimiento por parte del personal
              de salud vinculado a AUNA; <br />
              <br />
              2. Si el Usuario tiene síntomas que razonablemente lleven a determinar que ha contraído el COVID-19, su
              información le será remitida al Ministerio de Salud de Perú, de conformidad con la Resolución Ministerial
              No. 139-2020/MINSA. <br />
              <br />
              3. AUNA, eventualmente, podrá tratar información anonimizada con fines de investigación científica. Esta
              información, según las disposiciones de la Ley de Protección de Datos Personales, no se cataloga como
              Datos Personales, en tanto no identifica ni hace identificable al Usuario. <br />
              <br />
              4. Transferir sus Datos Personales a sus socios comerciales o empresas vinculadas, los cuales están
              descritos en el link www.auna.pe/empresas-vinculadas-y-socios-comerciales-auna/ con la finalidad de
              cumplir con cualquiera de los fines mencionados anteriormente. <br />
              <br />
              5. Compartir sus Datos Personales con los proveedores de servicios de las empresas afiliadas o vinculadas
              a AUNA, los cuales están descritos en el link www.auna.pe/empresas-vinculadas-y-socios-comerciales-auna/ ,
              a fin de que utilicen dicha información para mejorar o facilitar la operatividad de los sitios web o las
              aplicaciones, así como permitir la ejecución de los servicios solicitados por los Usuarios. <br />
              <br />
              6. AUNA contrata los servicios en la nube para el almacenamiento de sus datos personales a través de la
              empresa Google y Amazon, cuyos servidores se encuentran en Estados Unidos.
            </p>
            <p>
              Los Usuarios manifiestan expresamente que han sido informados de las finalidades de tratamiento y, a
              través de la aceptación de la presente Política de Privacidad, autorizan y otorgan su consentimiento, de
              manera previa, libre, expresa, inequívoca y gratuita, para el tratamiento de sus Datos Personales y para
              la inclusión de su información en el Banco de Datos denominado “COVID-19”, de titularidad de AUNA (Medic
              Ser S.A.C.).
            </p>
            <span>
              <strong>7. Resguardo de la información de los Usuarios</strong>
            </span>
            <p>
              AUNA adopta las medidas de seguridad necesarias para garantizar la protección de la información de los
              Usuarios a fin de evitar su alteración, pérdida, tratamiento y/o acceso no autorizado, tomando en
              consideración la naturaleza de la información y los riesgos a los que se encuentran expuestos. Para
              proteger los Datos Personales de los Usuarios, cumplimos estrictamente con lo establecido en la Ley y el
              Reglamento.
            </p>
            <span>
              <strong>8. Divulgación</strong>
            </span>
            <p>
              AUNA se compromete a no divulgar o compartir los Datos Personales de los Usuarios, sin que hayan prestado
              el debido consentimiento para ello, con excepción de los siguientes casos:
            </p>
            <p>
              ● Solicitudes de información de autoridades públicas en ejercicio de sus funciones y el ámbito de sus
              competencias. <br />
              <br />
              ● Solicitudes de información en virtud de órdenes judiciales. <br />
              <br />● Solicitudes de información en virtud de disposiciones legales.
            </p>
            <span>
              <strong>9. Cookies</strong>
            </span>
            <p>
              Algunos sitios web de AUNA podrán utilizar cookies, las cuales son pequeños archivos de texto que los
              sitios web almacenan en su ordenador, celular inteligente (Smartphone), Tablet o cualquier otro
              dispositivo de acceso a Internet. Las cookies sirven para reconocer el dispositivo de los Usuarios cuando
              vuelven a visitar el sitio web, facilitando su uso recordando sus preferencias y configuración de
              navegación (p. ej. idioma, país, etc.). También sirven para mejorar los servicios que ofrecemos y para
              poder recopilar información estadística que nos permite entender cómo los Usuarios utilizan nuestro sitio
              web y nos ayudan a mejorar su estructura y contenidos. Algunas cookies son estrictamente necesarias para
              que el sitio web funcione correctamente y otras sirven para mejorar el rendimiento y la experiencia del
              Usuario en los sitios web de AUNA.
            </p>
            <p>
              Las cookies que usamos en AUNA usan exclusivamente información anonimizada; es decir, información que no
              identifica ni hace identificable a los Usuarios de nuestras plataformas o sitios web, por lo que no se
              constituyen como Datos Personales según las disposiciones contenidas en la Ley de Protección de Datos
              Personales y demás normas sobre la materia. Las cookies no pueden dañar su dispositivo y además son muy
              útiles, puesto que nos ayudan a identificar y resolver errores.
            </p>
            <span>
              <strong>10. Ejercicio de derechos</strong>
            </span>
            <p>
              Los Usuarios puede ejercer los derechos de acceso, rectificación, oposición, cancelación y demás derechos
              consagrados en la Ley y su Reglamento mediante petición dirigida a: derechosarco@auna.pe o Avenida Angamos
              Oeste cuadra 4, esquina con Calle General Borgoño, distrito de Miraflores, Lima-Perú.
            </p>
            <p>
              Sin perjuicio de lo anterior, AUNA podrá conservar determinada información de los Usuarios de quien
              solicita la baja, a fin de que sirva de prueba ante una eventual reclamación contra AUNA por
              responsabilidades derivadas del tratamiento de dicha información. La duración de dicha conservación no
              podrá ser superior al plazo de prescripción legal de dichas responsabilidades.
            </p>
            <span>
              <strong>11. Edad de Consentimiento</strong>
            </span>
            <p>
              Al brindar sus datos personales a AUNA, los Usuarios declaran tener al menos catorce años de edad o ser
              tutor de un menor de edad para otorgar el consentimiento de forma válida de acuerdo a la Ley. AUNA no
              llevará a cabo voluntariamente el tratamiento de Datos Personales relativos a menores de edad, salvo que
              se cuente con el debido consentimiento de acuerdo a la norma aplicable. En el supuesto de que se tenga
              conocimiento que los Datos Personales recogidos corresponden a un menor de edad sin autorización de su
              tutor legal, se adoptarán las medidas oportunas para eliminarlos.
            </p>
            <span>
              <strong>12. Modificaciones de la Política de Privacidad</strong>
            </span>
            <p>
              AUNA se reserva expresamente el derecho a modificar, actualizar o completar en cualquier momento la
              presente Política de Privacidad. Cualquier modificación, actualización o ampliación producida en la
              presente Política será inmediatamente publicada en el sitio web.
            </p>
            <div className='col-8 col-lg-12 conditions__title px-0'>
              <h5 className='text-center mb-0'>Fines Adicionales</h5>
            </div>
            <br />
            <p>
              En armonía con el Decreto Legislativo No. 1390 que modifica el Artículo 58 del Código de Protección y
              Defensa del Consumidor, con su autorización Medic Ser S.A.C. (en adelante, AUNA) lo podrá contactar por
              medio de llamadas telefónicas, SMS, correos electrónicos u otros medios equivalentes, para informarle
              sobre sus productos o servicios y realizar encuestas de satisfacción. Asimismo, con su autorización, AUNA
              podrá compartir sus datos personales con las empresas vinculadas a AUNA o sus socios comerciales, los
              cuales están descritos en auna.pe/empresas-vinculadas-y-socios-comerciales-auna/ para las mismas
              finalidades.
            </p>
            <p>
              En el supuesto que no acepte el tratamiento adicional de sus datos personales, esto no afectará la
              prestación del servicio contratado o solicitado.
            </p>
          </div>
        </div>
      </div>
      <div className='p-lg-3'></div>

      <div className='d-none d-lg-block px-5'>
        <div className='mt-lg-2'></div>
        <div className='mx-5'>
          <ButtonPrimary button='Volver' route='/registro' />
        </div>
      </div>
    </>
  )
}

export default PrivacyPeru
