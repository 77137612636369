import React from 'react'
import './Paragraph.scss'

const Paragraph = ({ title, paragraph }) => (
  <div className='paragraph text-center px-4 px-lg-5 mx-lg-5'>
    <div className='py-lg-3'>
      <h2 className='py-3 pt-3 my-4 py-lg-0 py-lg-3'>{title}</h2>
    </div>
    <p className='text-center mb-lg-4'>{paragraph}</p>
  </div>
)

export default Paragraph
