const HeaderSteps = ({ image, subtitle }) => (
  <>
    <div className='mb-3 mb-lg-4 text-center'>
      <img src={image} alt='imagen paso tres de tres' />
    </div>
    <h2 className='text-center header__title mb-3 pb-3 px-4'>Adjunta tus imágenes</h2>

    <div className='px-4 px-lg-5 mx-lg-5'>
      <div className='pb-3 px-3 pt-lg-0 mb-lg-3'>
        <div className='row d-flex justify-content-between align-items-center'>
          <span className='message__conditions'>{subtitle}</span>
        </div>
      </div>
    </div>
  </>
)

export default HeaderSteps
