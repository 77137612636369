import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'

import firebase from 'utils/firebaseConfig'
import ArrowBack from 'assets/icons/arrowLeft.svg'
import Results from 'assets/icons/prescription.svg'
import { recommendations } from 'utils/recomendations.json'
import LogoTerms from 'components/atoms/LogoTerms'
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary'

const Recommendations = () => {
  const { name } = useParams()
  const find = (recommedations) => recommedations.url === name
  const findIt = recommendations.find(find)
  const { title, description } = findIt

  useEffect(() => {
    firebase.analytics().logEvent(`recomendaciones-${name}`)
  }, [name])

  return (
    <>
      <div className='px-4 pt-3'>
        <LogoTerms />
        <div className='row px-3 pb-4 d-flex align-items-center mb-2'>
          <div className='col-2 ml-0 pl-0 d-lg-none'>
            <Link to='/calculadora'>
              <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
            </Link>
          </div>
          <div className='col-8 col-lg-12 conditions__title'>
            <h5 className='text-center mb-0'>{title}</h5>
          </div>
        </div>

        <div className='conditions__container pt-2 pt-lg-3'>
          <div className='message__conditions'>
            {description.map((item) => (
              <div className='d-flex justify-content-start align-items-center pb-3 conditions' key={item}>
                <img src={Results} alt='imagen de resultados' />
                <p className='ml-2 mb-0 pb-0'>{item}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='pt-lg-5 px-lg-0 d-none d-lg-block'>
        <div className='mt-lg-1'></div>
        <ButtonPrimary button='Volver' route='/calculadora' />
      </div>
    </>
  )
}

export default Recommendations
