import React from 'react'
import { Modal } from 'react-bootstrap'

import Error from 'assets/light/error.svg'
import ImageError from 'components/atoms/ImageError'
import './error.scss'

export default function ErrorSystem({ modals, setModals }) {
  return (
    <>
      <Modal
        show={modals.errSystem}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        contentClassName='text-center borderadius'
      >
        <Modal.Header closeButton className='border-bottom-0 pb-0 button'></Modal.Header>
        <ImageError image={Error} alt='imagen de familia' />
        <p className='text-center pt-4'>El sistema no está disponible</p>
        <p className='pl-5 pr-5 mb-0 description'>Tus datos permanecerán guardados.</p>
        <div className='button__primary pt-lg-5 p-4'>
          <div className='px-lg-5 mx-lg-5'>
            <button onClick={() => setModals({ ...modals, errSystem: false })} className='btn btn-block btn-primary'>
              Intentar más tarde
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
