const ButtonSubmit = ({ text, padding = '30px' }) => (
  <div className='button__primary pt-lg-5' style={{ paddingTop: padding }}>
    <hr className='mb-2 d-lg-none' />
    <div className='px-4 mb-2 px-lg-5 mx-lg-5'>
      <button className='btn btn-block btn-primary' type='submit'>
        {text}
      </button>
    </div>
  </div>
)

export default ButtonSubmit
