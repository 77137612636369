import { useState, useEffect, useContext, useCallback } from 'react'
import { Link } from 'react-router-dom'

import Virus from 'assets/light/virus-icon.svg'
import Clock from 'assets/icons/clock.svg'
import CardHome from 'components/atoms/CardHome/CardHome'
import Loading from 'components/atoms/Loader/Loading'
import CardViewDate from 'components/atoms/CardView/CardViewDate'
import HeaderResponsive from 'components/molecules/HeaderResponsive'
import { request } from 'services/requestService'
import { formattedDate } from 'utils/helpers'
import { AppContext } from 'context/AppContext'

// sort latest dates in array
const latest = (a, b) => {
  let lastDate = 0
  a.date > b.date ? (lastDate = -1) : (lastDate = 1)
  return lastDate
}

function SelectNewEffectHistorial({ history }) {
  const { historySideEffects, setHistorySideEffects, getUrl } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)

  const sorted = historySideEffects.sort(latest)
  const route = sorted[0]?.doses === 1 ? 'uno' : 'dos'

  const getAdverses = useCallback(async () => {
    setIsLoading(true)
    request('get', 'adverse')
      .then((data) => {
        setHistorySideEffects(data.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
        history.push('/home')
      })
  }, [history, setHistorySideEffects])

  useEffect(() => {
    getAdverses()
    // sort latest dates in array
  }, [getAdverses])

  useEffect(() => {
    if (getUrl === 'sms') {
      history.push('/dosis-efectos-adversos')
    }
  }, [])

  if (isLoading) return <Loading />

  return (
    <div className='px-4 px-lg-5 mx-lg-5'>
      <HeaderResponsive route='/home' title='Reacciones adversas' />
      <div className='pt-3'></div>
      <CardHome icon={Virus} title='Nueva reacción adversa' route='/dosis-efectos-adversos' alt='icono de virus' />
      <CardHome
        icon={Clock}
        title='Historial de reacciones'
        alt='icono de reloj'
        route='/historial-dosis'
        disabled={sorted[0]?.date ? '' : true}
      />
      <div className='pb-2 pt-4'>
        <p className='message__last mt-lg-3'>Último registro de reacciones adversas</p>

        <div className='ani-show'>
          {sorted[0]?.date ? (
            <div className='pb-4'>
              <CardViewDate
                title={formattedDate(sorted[0]?.date)}
                route={`${route}/dosis-${route}/${sorted[0]?.date}`}
              />
            </div>
          ) : (
            <p className='text-center message__final pb-4 mt-1'>
              No tienes registrado reacciones <br />
              adversas todavía.
            </p>
          )}
        </div>

        <div className='d-none d-lg-block px-3'>
          <div className='pb-lg-3'></div>
          <Link to='/home' className='btn btn-outline-secondary'>
            Atrás
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SelectNewEffectHistorial
