import { React, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import DoubleButton from 'components/molecules/DoubleButton'
import { onClickEnterButton } from 'utils/helpers'

const FormCode = ({
  onConfirm,
  countryCodeValue,
  phone,
  code,
  setCode,
  showError,
  setIsAction,
  setTimeOutRender,
  countDown,
}) => {
  const { register, errors, setValue, handleSubmit } = useForm()

  /* useEffect for OTP Autocomplete handling */
  useEffect(() => {
    /* OTPCredential Object is present in window when the client is a mobile phone */

    if ('OTPCredential' in window) {
      console.log('Loading OTP...')
      const input = document.querySelector('input[autocomplete="one-time-code"]')

      if (!input) return

      const ac = new AbortController()
      const form = input.closest('form')

      navigator.credentials
        .get({
          otp: { transport: ['sms'] },
          signal: ac.signal,
        })
        .then((otp) => {
          onConfirm(otp.code)
            .then()
            .catch((err) => {
              console.log('Error Handling Info Submition')
              console.log(err)
            })
        })
        .catch((err) => {
          console.log('Error Handling OTP Autocomplete')
          console.log(err)
        })
    }
  }, []) // eslint-disable-line

  const submitCode = handleSubmit(onConfirm)

  return (
    <form onSubmit={submitCode}>
      <div className='px-4 px-lg-5 mx-lg-5 pb-lg-5 pt-2 pt-lg-0'>
        <h6 className='message__code pt-lg-0 mb-lg-0 pb-3 pb-lg-0'>
          Hemos enviado un código de 6 dígitos por SMS al número {countryCodeValue + phone}
        </h6>
        <label htmlFor='code' className='form-label mb-1'>
          Código
        </label>
        <input
          className='form-control'
          id='recaptcha-container'
          name='code'
          onChange={(e) => setCode(e.target.value)}
          placeholder='Ingresa el código de 6 dígitos'
          inputMode='numeric'
          autocomplete='one-time-code'
          ref={register({
            required: {
              value: true,
              message: 'Debes ingresar el código de 6 dígitos',
            },
            maxLength: {
              value: 6,
              message: 'Debes ingresar el código de 6 dígitos',
            },
            minLength: {
              value: 6,
              message: 'Debes ingresar el código de 6 dígitos',
            },
          })}
          onKeyDown={(e) => onClickEnterButton(setValue, 'code', e, submitCode)}
        />
        <span className=' text-small d-block px-3 message__error'>{errors?.code?.message}</span>
        <span className='message__error text-small d-block px-3'>{showError && <h1>{showError}</h1>}</span>
      </div>
      <div className='my-lg-4 pt-lg-4 pt-1'></div>
      <div className='pb-1'></div>

      <div className='pt-lg-4 pt-5'></div>
      <div className='pt-2 d-lg-none'></div>
      <div className='pt-4 mt-5 d-lg-none'></div>

      <DoubleButton setIsAction={setIsAction} code={code} setTimeOutRender={setTimeOutRender} countDown={countDown} />
    </form>
  )
}

export default FormCode
