import React, { forwardRef } from 'react'
import Calendar from 'assets/icons/calendar.svg'
import Alert from 'assets/icons/alertWarning.svg'

const CustomDatePicker = forwardRef(({ value, onClick, errorDate }, ref) => (
  <button
    type='button'
    className={`form-control button__datepicker ${errorDate && 'button__datepicker-error'} `}
    onClick={onClick}
    ref={ref}
  >
    <p>{value === 'Fecha inválida' ? 'dd/mm/yyyy' : value}</p>
    {errorDate ? <img src={Alert} alt='icono de warning' /> : <img src={Calendar} alt='icono de calendario' />}
  </button>
))

export default CustomDatePicker
