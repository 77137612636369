import React from 'react'

const DoubleButton = ({ setIsAction, code, setTimeOutRender, countDown }) => {
  return (
    <div className='button__primary pt-lg-5 mt-lg-5'>
      <div className='row px-4 mx-lg-5 pt-lg-4'>
        <div className='col pl-lg-4 pr-2 '>
          <button
            className='btn btn-outline-secondary'
            type='button'
            onClick={() => {
              setIsAction(false)
              setTimeOutRender(true)
              countDown()
            }}
          >
            Atrás
          </button>
        </div>
        <div className='col pl-2 pr-lg-4'>
          <button className='btn btn-primary' type='submit' disabled={code.length >= 1 ? false : true}>
            Validar
          </button>
        </div>
      </div>
    </div>
  )
}

export default DoubleButton
