import React, { useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import One from 'assets/stepper/Paso1.svg'
import Title from 'components/atoms/Title/Title'
import ButtonSubmit from 'components/atoms/Button/ButtonSubmit'
import { AppContext } from 'context/AppContext'
import { bodyMass } from 'utils/helpers'
import { triageSchema } from 'Validations/SchemaValidation'

function Step1() {
  const { setStep, userData, setUserData, handleChange } = useContext(AppContext)
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(triageSchema),
  })
  const [index, setIndex] = useState(0)
  const bmi = bodyMass(userData)

  const handleForm = () => {
    setUserData({ ...userData, bmi: +index, height: +userData.height, weight: +userData.weight })
    setStep(2)
  }

  useEffect(() => {
    setIndex(bmi)
  }, [bmi])

  return (
    <>
      <div className='mb-3 mb-lg-4 text-center'>
        <img src={One} alt='imagen paso uno de tres' />
      </div>
      <div></div>
      <Title title='Ingresa tus datos' />
      <form onSubmit={handleSubmit(handleForm)}>
        <div className='px-4'>
          <div className='row'>
            <div className='col-sm-12 col-lg-6 pr-lg-2'>
              <label className='form-step' htmlFor='weight'>
                Peso en kg
              </label>
              <input
                className={`form-control ${errors?.weight && 'form-control-error'} `}
                type='number'
                name='weight'
                min='40'
                max='200'
                value={userData['weight']}
                onChange={handleChange}
                placeholder='Ej.: 68'
                ref={register}
              />
              <span
                className={`${errors?.weight ? 'message__error' : 'text-white'} text-small d-block pl-3 message__error`}
              >
                Debes ingresar tu peso en kg
              </span>
            </div>

            <div className='col-sm-12 col-lg-6 pl-lg-2'>
              <label className='form-step' htmlFor='height'>
                Altura en cm
              </label>
              <input
                className={`form-control ${errors?.height && 'form-control-error'} `}
                type='number'
                name='height'
                min='100'
                max='250'
                value={userData['height']}
                onChange={handleChange}
                placeholder='Ej.: 170'
                ref={register}
              />
              <span className={`${errors?.height ? 'message__error' : 'text-white'} text-small d-block pl-3`}>
                Debes ingresar tu altura en cm
              </span>
            </div>
          </div>
        </div>
        <div className='py-lg-5 my-lg-4'></div>
        <div className='py-lg-4 my-lg-2'></div>
        <div className='p-lg-1'></div>
        <div className='py-5 py-lg-0'></div>

        <div className='d-none d-lg-block'>
          <ButtonSubmit text='Siguiente' />
        </div>

        <div className='fixed-bottom d-lg-none'>
          <ButtonSubmit text='Siguiente' padding='0' />
        </div>
      </form>
    </>
  )
}

export default Step1
