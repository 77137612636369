import * as yup from 'yup'

export const userSchema = yup.object().shape({
  name: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  documentNumber: yup.number().positive().integer().required(),
  birthDate: yup.date(),
  gender: yup.string().required(),
})

export const triageSchema = yup.object().shape({
  weight: yup.number().positive().min(40).integer().required(),
  height: yup.number().positive().min(100).integer().required(),
})

export const vaccineSchema = yup.object().shape({
  manufacturer: yup.string().required(),
  vaccineCenter: yup.string().strict(true),
  vaccinatorName: yup.string().strict(),
})
