import { Route } from 'react-router-dom'

import ReactionsOnboarding from 'components/templates/SideEffects/ReactionsOnboarding'
import ValidationSideEffects from 'components/templates/SideEffects/ValidationSideEffects'
import SideEffectIdentification from 'components/templates/SideEffects/SideEffectIdentification'
import StepSideEffectsDosesOne from 'components/templates/SideEffects/StepSideEffectsDosesOne'
import SelectDosesSideEffect from 'components/templates/SideEffects/SelectDosesSideEffect'
import SelectNewEffectHistorial from 'components/templates/SideEffects/SelectNewEffectHistorial'
import DosesHistorial from 'components/templates/SideEffects/Historial/DosesHistorial'
import HistorialInfoOne from 'components/templates/SideEffects/Historial/HistorialInfoOne'
import HistorialInfoTwo from 'components/templates/SideEffects/Historial/HistorialInfoTwo'
import HistorialDetail from 'components/templates/SideEffects/Historial/HistorialDetail'
import FinalSide from 'components/templates/Final/FinalSide'

const SideEffectsRoute = () => (
  <>
    <Route exact path='/nueva-reaccion-adversa' component={SelectNewEffectHistorial} />
    <Route exact path='/dosis-efectos-adversos' component={SelectDosesSideEffect} />
    <Route exact path='/que-son-efectos-adversos' component={ReactionsOnboarding} />
    <Route exact path='/tuviste-reaccion-adversa' component={ValidationSideEffects} />
    <Route exact path='/reacciones-onboarding' component={SideEffectIdentification} />
    <Route exact path='/efectos-adversos-dosis' component={StepSideEffectsDosesOne} />
    <Route exact path='/historial-dosis' component={DosesHistorial} />
    <Route exact path='/historial-dosis-uno' component={HistorialInfoOne} />
    <Route exact path='/historial-dosis-uno/:dosis/:date' component={HistorialDetail} />
    <Route exact path='/historial-dosis-dos' component={HistorialInfoTwo} />
    <Route exact path='/historial-dosis-dos/:dosis/:date' component={HistorialDetail} />
    <Route exact path='/finalizar-efectos' component={FinalSide} />
  </>
)

export default SideEffectsRoute
