import { Auth } from 'aws-amplify'
import axios from 'axios'

/* const endpoints = {
  user: 'https://7om3xh616f.execute-api.us-east-1.amazonaws.com/dev/user',
  triage: 'https://lagexsln71.execute-api.us-east-1.amazonaws.com/dev/triage',
  vaccine: 'https://iwqv23e5x3.execute-api.us-east-1.amazonaws.com/dev/vaccine',
  adverse: 'https://dk6vo0t7pe.execute-api.us-east-1.amazonaws.com/dev/adverse',
  getSignedUrl : 'https://1t6pzevr8c.execute-api.us-east-1.amazonaws.com/uploads'
} */

/* const endpoints = {
  user: 'https://ja0znu0n05.execute-api.us-east-1.amazonaws.com/dev/user',
  triage: 'https://gsc739zp36.execute-api.us-east-1.amazonaws.com/dev/triage',
  vaccine: 'https://emhc0c5f14.execute-api.us-east-1.amazonaws.com/dev/vaccine',
  adverse: 'https://2kob9o8eri.execute-api.us-east-1.amazonaws.com/dev/adverse',
  getSignedUrl : 'https://ltay9m0sa8.execute-api.us-east-1.amazonaws.com/uploads'
} */
/*
const endpoints = {
  user: 'https://yj4od6swb9.execute-api.us-east-1.amazonaws.com/dev/user',
  triage: 'https://5jm23p2wg6.execute-api.us-east-1.amazonaws.com/dev/triage',
  vaccine: 'https://3mryirq40d.execute-api.us-east-1.amazonaws.com/dev/vaccine',
  adverse: 'https://mdpraskqpa.execute-api.us-east-1.amazonaws.com/dev/adverse',
  getSignedUrl: 'https://je8pm4qzc3.execute-api.us-east-1.amazonaws.com/uploads',
}
*/
//PRD
const endpoints = {
  user: 'https://nbk8jiwe86.execute-api.us-east-1.amazonaws.com/prd/user',
  triage: 'https://6qnfo2ftab.execute-api.us-east-1.amazonaws.com/prd/triage',
  vaccine: 'https://09fejnkzae.execute-api.us-east-1.amazonaws.com/prd/vaccine',
  adverse: ' https://ps49veczu3.execute-api.us-east-1.amazonaws.com/prd/adverse',
  getSignedUrl: 'https://mt2dyvf126.execute-api.us-east-1.amazonaws.com/uploads',
}
const newsletterUrl =
  'https://forms.hsforms.com/emailcheck/v1/json-ext?portalId=2202832&includeFreemailSuggestions=true'

const locationUrl = 'https://extreme-ip-lookup.com/json/'

/**
 * Request to the Backend
 * @param {String} method the request method
 * @param {String} url endpoint required
 * @param {Object} [data] Object to save in db
 * @return {Promise}
 */

export const request = async (method, url, data = null) => {
  let token
  try {
    token = (await Auth.currentSession()).getIdToken().getJwtToken()
  } catch (error) {
    throw error
  }

  /**
   * @param {String} headers
   */
  const req = await axios({
    method,
    url: endpoints[url],
    data,
    headers: {
      Authorization: token,
    },
  })

  return req
}
/**
 * Pass the url and blob object to create an url in aws
 * @param {String} signedUrl
 * @param {Object} blobData
 * @returns {Promise}
 */
export const uploadFile = async (signedUrl, blobData) => {
  const result = await fetch(signedUrl, {
    method: 'PUT',
    body: blobData,
  })
  return result
}

/**
 * Suscribe to the Auna Newsletter
 * @param {String} email
 * @returns {Promise}
 */
export const suscribeNewsletter = async (email) => {
  const req = await axios({
    method: 'post',
    url: newsletterUrl,
    data: email,
  })

  return req
}
/**
 * Get the data of the current location
 * @returns {Promise} A object with data location
 */
export const getGeoData = async () => {
  const response = await axios(locationUrl)
  const { data } = response
  return data
}
