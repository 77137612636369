import { useState, useContext } from 'react'
import { withRouter } from 'react-router'

import Loading from 'components/atoms/Loader/Loading'
import FormCode from 'components/organisms/Login/FormCode'
import { AppContext } from 'context/AppContext'
import { request } from 'services/requestService'

function CodeVerification({
  countryCodeValue,
  phone,
  history,
  setIsAction,
  phoneAuth,
  Auth,
  setModals,
  isLoading,
  setIsLoading,
  modals,
  setTimeOutRender,
  countDown,
}) {
  const { setUser, setIsAuth } = useContext(AppContext)
  const [code, setCode] = useState('')

  const onConfirm = async (codeOtp = null) => {
    setIsLoading(true)
    try {
      if (!codeOtp.hasOwnProperty('code')) {
        await Auth.sendCustomChallengeAnswer(phoneAuth, codeOtp)
      } else {
        await Auth.sendCustomChallengeAnswer(phoneAuth, code)
      }

      await Auth.currentSession()

      request('get', 'user')
        .then(({ data }) => {
          /** if there's an user registered return data */
          setIsLoading(false)
          setUser(data)
          history.push('/home')
          setIsAuth(true)
        })
        .catch((err) => {
          setIsLoading(false)

          console.log(err)
          if (err.hasOwnProperty('code')) {
            if (err.code === 'NetworkError') {
              setModals({ ...modals, errNetwork: true })
              return
            }
          } else if (err.response.status === 404) {
            /** if there's no an user registered go to register page */
            history.push('/registro')
            setIsAuth(true)
          }
        })
    } catch (error) {
      setIsLoading(false)
      console.log(error)
      if (error.hasOwnProperty('code')) {
        if (error.code === 'NotAuthorizedException') {
          setIsAction(false)
          setIsLoading(false)
          return
        }
      }
      setModals({ ...modals, errCode: true })
    }
  }

  const props = { onConfirm, countryCodeValue, phone, code, setCode, setIsAction, setTimeOutRender, countDown }

  return <>{isLoading ? <Loading /> : <FormCode {...props} />}</>
}

export default withRouter(CodeVerification)
