import { Link } from 'react-router-dom'

import Papper from 'assets/icons/papper-icon.svg'
import ArrowRight from 'assets/icons/arrowRight.svg'

const ButtonTakePicture = ({ title, route, click }) => (
  <Link to={route} className='recommend' onClick={click}>
    <div className='card__view d-flex justify-content-around align-items-center mb-4'>
      <img src={Papper} alt='icono de información' className='info__icon' />
      <p className='py-4 mb-0'>{title}</p>
      <img src={ArrowRight} alt='flecha apuntando hacia la derecha' />
    </div>
  </Link>
)

export default ButtonTakePicture
