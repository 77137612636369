import React from 'react'
import { Link } from 'react-router-dom'

const PrivacyCountry = () => (
  <div className='d-flex align-items-center justify-content-center h-100'>
    <div>
      <h6 className='text-center message__info pb-lg-2'>Seleccione según su país</h6>
      <div className='py-2'></div>

      <div className='pt-lg-2 pt-4'>
        <div className='row'>
          <div className='col-sm-12 col-lg-6 pr-lg-2 pl-lg-0'>
            <div className='button__primary' style={{ paddingTop: 0 }}>
              <Link to='/privacidad-peru' className='btn btn-block btn-primary'>
                Perú
              </Link>
            </div>
            <div className='mb-2'></div>
          </div>
          <div className='col-sm-12 col-lg-6 pl-lg-2 pr-lg-0'>
            <div className='button__primary' style={{ paddingTop: 0 }}>
              <Link to='/privacidad-colombia' className='btn btn-block btn-primary'>
                Colombia
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PrivacyCountry
