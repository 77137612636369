import ElementSideEffects from 'components/organisms/SideEffects/ElementSideEffects'

function ListSymptoms({ symptoms }) {
  const {
    cough,
    chills,
    fatigue,
    headache,
    fever,
    musclePain,
    swollenGlands,
    injectionInflammation,
    sickness,
    vomit,
    dizziness,
    decreasedAppetite,
    diarrhea,
  } = symptoms

  return (
    <>
      {fatigue && <ElementSideEffects title='Fatiga' />}
      {headache && <ElementSideEffects title='Dolor de cabeza' />}
      {fever && <ElementSideEffects title='Fiebre: mayor a 37,3°C' />}
      {musclePain && <ElementSideEffects title='Dolor muscular/articulaciones' />}
      {chills && <ElementSideEffects title='Escalofríos' />}
      {cough && <ElementSideEffects title='Tos' />}
      {swollenGlands && (
        <ElementSideEffects title='Inflamación de los gánglios linfáticos (generalmente como bulto en la clavícula)' />
      )}
      {injectionInflammation && (
        <ElementSideEffects title='Dolor, inflamación, erupción en el lugar de la inyección.' />
      )}
      {sickness && <ElementSideEffects title='Náuseas' />}
      {vomit && <ElementSideEffects title='Vómitos' />}
      {dizziness && <ElementSideEffects title='Mareos' />}
      {decreasedAppetite && <ElementSideEffects title='Disminución de apetito' />}
      {diarrhea && <ElementSideEffects title='Diarrea' />}
    </>
  )
}

export default ListSymptoms
