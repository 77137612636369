import { useContext } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import es from 'date-fns/locale/es'

import ButtonSubmit from 'components/atoms/Button/ButtonSubmit'
import CustomDatePicker from 'components/atoms/Input/CustomDatePicker'
import { vaccineSchema } from 'Validations/SchemaValidation'
import { AppContext } from 'context/AppContext'
registerLocale('es', es)

function FormVaccine({ vaccine, vaccinationDate, handleVaccine, handleChange, onChange, text }) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(vaccineSchema),
  })

  const { dosesOne } = useContext(AppContext)

  return (
    <form onSubmit={handleSubmit(handleVaccine)}>
      <div className='px-4'>
        <div className='mb-4'>
          <h5 className='text-left message__doses-info '>Datos de registro de vacuna</h5>
        </div>

        <div className='row'>
          <div className='col-sm-12 '>
            <label className='form-label mb-0 mt-lg-0' htmlFor='doses' style={{ color: '#B8BCD5' }}>
              Dosis
            </label>
            <input className='form-control form-control__disabled' type='text' name='doses' value={text} disabled />
            <span className='text-white'> dosis</span>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 col-lg-6'>
            <label className='form-label mb-0  mt-lg-0' htmlFor='vaccinationDate'>
              Fecha de vacunación*
            </label>
            <DatePicker
              type='date'
              selected={vaccinationDate}
              value={moment(vaccine?.vaccinationDate).format('L')}
              onChange={onChange}
              locale='es'
              className='form-control form__datepicker'
              dateFormat='dd/MM/yyyy'
              placeholderText='dd/mm/aaaa'
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              minDate={new Date('2021-01-02')}
              maxDate={new Date()}
              customInput={<CustomDatePicker />}
            />
            <span
              className={`${
                vaccine.vaccinationDate === '' ? 'message__error' : 'text-white'
              } text-small d-block pl-2 message__error`}
            >
              Ingresa fecha válida
            </span>
          </div>

          <div className='col-sm-12 col-lg-6'>
            <label className='form-label mb-0 mt-lg-0' htmlFor='factory'>
              Nombre del fabricante*
            </label>
            <select
              className={`form-control ${errors?.manufacturer && 'form-control-error'} `}
              name='manufacturer'
              onChange={handleChange}
              ref={register}
              value={dosesOne?.manufacturer ? dosesOne?.manufacturer : vaccine.manufacturer}
            >
              <option value='' disabled>
                Selecciona el fabricante
              </option>
              <option value='sinovac-coronavac'>Sinovac/CoronaVac</option>
              <option value='sinopharm'>Sinopharm</option>
              <option value='astrazeneca'>AstraZeneca</option>
              <option value='pfizer-biontech'>Pfizer/BioNTech</option>
              <option value='moderna'>Moderna</option>
              <option value='johnson_&_johnson'>Johnson & Johnson</option>
              <option value='anhui_zhifei'>Anhui Zhifei</option>
              <option value='bharatbiotech'>BharatBiotech</option>
              <option value='cansino'>CanSino</option>
              <option value='gamaleya-sputnikV'>Gamaleya/sputnik V</option>
              <option value='janssen'>Janssen</option>
              <option value='medicago'>Medicago</option>
              <option value='novavax'>Novavax</option>
            </select>
            <span
              className={`${errors?.manufacturer?.message ? 'message__error' : 'text-white'} text-small d-block pl-3`}
            >
              Debes seleccionar fabricante
            </span>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 col-lg-6'>
            <label htmlFor='lote' className='form-label mb-0 mt-lg-0'>
              Lote
            </label>
            <input
              className='form-control'
              name='lote'
              placeholder='Ingresa el número de lote'
              type='text'
              minLength='6'
              onChange={handleChange}
              ref={register}
              value={vaccine?.lote}
            />
            <span className={`${errors?.lote?.message ? 'message__error' : 'text-white'} text-small d-block pl-3`}>
              Debes ingresar un valor numérico
            </span>
          </div>

          <div className='col-sm-12 col-lg-6'>
            <label htmlFor='vaccineCenter' className='form-label mb-0 mt-lg-0'>
              Centro vacunador
            </label>
            <input
              className='form-control'
              type='text'
              name='vaccineCenter'
              placeholder='Ingresa centro vacunador'
              onChange={handleChange}
              ref={register}
              value={vaccine?.vaccineCenter}
            />
            <span className='text-white'> centro</span>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 col-lg-6'>
            <label htmlFor='vaccinatorName' className='form-label mb-0 mt-lg-0'>
              Nombre del vacunador
            </label>
            <input
              className='form-control'
              name='vaccinatorName'
              placeholder='Ingresa nombre del vacunador'
              onChange={handleChange}
              ref={register}
              value={vaccine?.vaccinatorName}
            />
            <span className='text-white'> vacunador</span>
          </div>

          <div className='col-sm-12 col-lg-6'>
            <label htmlFor='vaccinatorId' className='form-label mb-0 mt-lg-0'>
              Cédula del vacunador
            </label>
            <input
              className='form-control'
              name='vaccinatorId'
              placeholder='Ingresa cédula del vacunador'
              inputMode='numeric'
              pattern='[0-9]{4,12}'
              minLength='7'
              onChange={handleChange}
              ref={register}
              value={vaccine?.vaccinatorId}
            />
            <span
              className={`${errors?.vaccinatorId?.message ? 'message__error' : 'text-white'} text-small d-block pl-3`}
            >
              Debes ingresar un valor numérico
            </span>
          </div>
        </div>
      </div>

      <div className='pt-2 my-2'></div>
      <ButtonSubmit text='Confirmar' />
    </form>
  )
}

export default FormVaccine
