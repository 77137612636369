import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import ButtonTakePicture from 'components/atoms/Button/ButtonTakePicture'
import Header from 'components/atoms/Header/Header'
import Loading from 'components/atoms/Loader/Loading'
import FormConfirmation from 'components/organisms/VaccineForm/FormConfirmation'
import ErrorNetwork from 'components/organisms/Errors/ErrorNetwork'
import ErrorSession from 'components/organisms/Errors/ErrorSession'
import ErrorSystem from 'components/organisms/Errors/ErrorSystem'
import { request, uploadFile } from 'services/requestService'
import { AppContext } from 'context/AppContext'
import { getImage } from 'services/imageService'

function VaccineConfirmationForm({ vaccine, vaccinationDate, handleChange, onChange, text }) {
  const { setStep, setPreviewFrontal, setPreviewBack } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [modals, setModals] = useState({ errCode: false, errNetwork: false, errSession: false, errSystem: false })
  const history = useHistory()

  const seePictureFront = () => {
    setStep(3)
  }

  const seePictureBack = () => {
    setStep(4)
  }

  const handleVaccine = async () => {
    const imgData = getImage()
    setIsLoading(true)

    try {
      const signedUrl1 = await request('post', 'getSignedUrl', { type: imgData.front.type })
      await uploadFile(signedUrl1.data.uploadURL, imgData.front.blob)
      vaccine.imgFront = signedUrl1.data.uploadURL.split('?')[0]

      const signedUrl2 = await request('post', 'getSignedUrl', { type: imgData.back.type })
      await uploadFile(signedUrl2.data.uploadURL, imgData.back.blob)
      vaccine.imgBack = signedUrl2.data.uploadURL.split('?')[0]

      const vaccineData = {
        ...vaccine,
        vaccinatorName: vaccine.vaccinatorName.toLowerCase(),
        vaccineCenter: vaccine.vaccineCenter.toLowerCase(),
      }

      request('post', 'vaccine', vaccineData)
        .then(() => {
          setIsLoading(false)
          history.push('/finalizar-vacuna')
          setStep(1)
          setPreviewFrontal('')
          setPreviewBack('')
        })
        .catch((error) => {
          setIsLoading(false)
          if (error.hasOwnProperty('response')) {
            if (error.response.status === 400) {
              setModals({ ...modals, errSession: true })
              return
            } else {
              setModals({ ...modals, errSystem: true })
              return
            }
          }
          if (error.hasOwnProperty('code')) {
            if (error.code === 'NetworkError') {
              setModals({ ...modals, errNetwork: true })
              return
            }
          }

          if (error === 'No current user') {
            //Error session
            setModals({ ...modals, errSession: true })
            history.push('/')
          }
        })
    } catch (error) {
      setIsLoading(false)
      console.error(error.message)
      history.push('/home')
    }
  }

  const props = { vaccine, vaccinationDate, handleChange, onChange, handleVaccine, modals, setModals }

  const propsModals = {
    modals,
    setModals,
  }

  if (isLoading) return <Loading />

  return (
    <>
      <Header />
      <h2 className='text-center header__title pb-3 mb-0'>Confirmación</h2>
      <div className='pt-3'></div>
      <div className='px-4'>
        <div className='mb-4 pb-2'>
          <h5 className='text-left message__doses-info mb-4'>Imágenes adjuntas</h5>
          <ButtonTakePicture title='Frente de la cartilla' route='#' click={seePictureFront} />
          <ButtonTakePicture title='Dorso de la cartilla' route='#' click={seePictureBack} />
        </div>
      </div>
      <FormConfirmation {...props} text={text} />
      <ErrorSystem {...propsModals} />
      <ErrorSession {...propsModals} />
      <ErrorNetwork {...propsModals} />
    </>
  )
}

export default VaccineConfirmationForm
