import React from 'react'

const AddImageMessage = ({ text }) => (
  <p className='d-flex align-items-center justify-content-center text-center message__final pb-4 mt-1 message__image'>
    Adjunta la foto {text} <br />
    de tu cartilla de vacunación.
  </p>
)

export default AddImageMessage
