import './Toggle.scss'

const Toggle = ({ userData, handleChange }) => (
  <div className='switch_holder form-group pt-2 pb-3 px-3 pt-lg-0 pb-lg-3'>
    <div className='row d-flex justify-content-between align-items-center'>
      <span className='text__subtitle'>¿Es trabajador esencial?</span>
      <div className='main_switch'>
        <input
          type='checkbox'
          className='switch pr-3 mb-0'
          id='essentialJob'
          name='essentialJob'
          checked={userData['essentialJob']}
          onChange={handleChange}
        />
        <label htmlFor='essentialJob' className='pl-3 mb-0'></label>
      </div>
    </div>
  </div>
)

export default Toggle
