import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import Calendar from 'assets/light/calendar_icon.svg'
import CardHome from 'components/atoms/CardHome/CardHome'
import HeaderResponsive from 'components/molecules/HeaderResponsive'
import { AppContext } from 'context/AppContext'

function SelectDoses() {
  const { dosesOne, dosesTwo } = useContext(AppContext)
  const [routeOne, setRouteOne] = useState('')
  const [routeTwo, setRouteTwo] = useState('')

  useEffect(() => {
    /** Validate if theres a first vaccines register to select route */
    const routeDosesOne = dosesOne?.vaccinationDate ? '/registro-vacuna-dosis-uno' : '/vacunacion'
    const routeDosesTwo = dosesTwo?.vaccinationDate ? '/registro-vacuna-dosis-dos' : '/vacunacion'
    setRouteOne(routeDosesOne)
    setRouteTwo(routeDosesTwo)
  }, [dosesOne, dosesTwo])

  return (
    <div className='px-4 pt-lg-0 px-lg-5 mx-lg-5'>
      <HeaderResponsive route='/home' title='Registro de vacunas' />
      <div className='pt-3'></div>
      <CardHome icon={Calendar} title='Dosis 1' route={routeOne} alt='icono de calendario' />
      <CardHome
        title='Dosis 2'
        icon={Calendar}
        route={routeTwo}
        alt='icono de calendario'
        disabled={dosesOne?.vaccinationDate ? '' : true}
      />
      <div className='py-lg-5 px-lg-0 d-none d-lg-block '>
        <div className='mt-lg-5'></div>
        <div className='py-lg-5'></div>

        <Link to='/home' className='btn btn-outline-secondary'>
          Atrás
        </Link>
      </div>
    </div>
  )
}

export default SelectDoses
