import { Link } from 'react-router-dom'

import Header from '../atoms/Header/Header'
import ArrowBack from 'assets/icons/arrowLeft.svg'

const HeaderResponsive = ({ route, title }) => (
  <>
    <div className='d-none d-lg-block'>
      <Header />
    </div>
    <div className='row d-flex align-items-center px-3 pb-4 mb-3 pt-3 pt-lg-0'>
      <div className='col-2 ml-0 pl-0 d-lg-none'>
        <Link to={route}>
          <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
        </Link>
      </div>

      <div className='col-8 col-lg-12 conditions__title'>
        <h5 className='text-center mb-0'>{title}</h5>
      </div>
    </div>
  </>
)

export default HeaderResponsive
