import moment from 'moment'
import 'moment/locale/es'

/**
 * Calculate the Body Mass Index
 * @param {Object} data user data
 * @returns {String} A number with 2 decimal
 */
export const bodyMass = (data) => {
  const { weight, height } = data
  const mass = weight / Math.pow(height / 100, 2)
  return mass.toFixed(2)
}

/**
 * Get age of the user according the birth date
 * @param {Object} data user data in global state
 */
export const getAge = (data) => {
  const { birthDate } = data
  const birth = new Date(birthDate)
  const today = new Date()
  const age = parseInt((today - birth) / (1000 * 60 * 60 * 24 * 365)) // convert milliseconds to years
  return age
}

/**
 * Calculate the user factor risk according to the conditions provided
 * @param {Object} user
 * @param {Object} data
 * @returns {Number|String}
 */
export const calculateRisk = (user, data) => {
  const { age } = user
  const { essentialJob, bmi, immunosuppressive, pregnancy, hypertension, pulmonary, renal, diabetes } = data
  const jobFactor = essentialJob ? 0.174 : 0
  const ageFactor = age > 60 ? 0.6 : 0.5
  const bmiFactor = bmi > 30 ? 0.087 : 0
  const immuFactor = immunosuppressive ? 0.025 : 0
  const pregnanFactor = pregnancy ? 0.025 : 0
  const hyperFactor = hypertension ? 0.05 : 0
  const pulmonFactor = pulmonary ? 0.05 : 0
  const diabetesFactor = diabetes ? 0.05 : 0
  const renalFactor = renal ? 0.05 : 0

  const sumFactors = (
    jobFactor +
    ageFactor +
    bmiFactor +
    immuFactor +
    pregnanFactor +
    hyperFactor +
    pulmonFactor +
    renalFactor +
    diabetesFactor
  ).toFixed(2)

  return sumFactors >= 1 ? 1 : sumFactors
}

/**
 * This become the date into the format 'day dd/mm/yyyy'
 * @param {String} date get the date
 * @returns {String} Get the day in Capital Case and calendar date
 */
export const formattedDate = (date) => {
  const day = moment(date).format('dddd')
  const dayCapitalized = day.toUpperCase().charAt(0) + day.substring(1)
  const calendar = moment(date).format('l')

  return `${dayCapitalized} ${calendar}`
}

/**
 * Validate if there's a side effects registered the current day
 * @param {Object} historyData
 * @param {String} date
 * @returns {Object}
 */
export const findDate = (historyData, date) => {
  const searchDate = (historyData) => historyData?.date === date
  const symptoms = historyData.find(searchDate)

  return symptoms
}

// Submit form with Enter button
export const onClickEnterButton = (set, name, e, fn) => {
  if (e.key === 'Enter') {
    set(name, e.target.value)
    fn()
  }
}

