import React from 'react'

const Loading = () => {
  return (
    <div className='d-flex justify-content-center align-items-center image__loader'>
      <span className='message__loading '>Cargando imagen...</span>
    </div>
  )
}

export default Loading
