import React from 'react'
import Loader from 'assets/loader.gif'

const Loading = () => {
  return (
    <div className='d-flex justify-content-center align-items-center image__loader'>
      <img style={{ width: '10%' }} src={Loader} alt='loader de auna' />
    </div>
  )
}

export default Loading
