import React from 'react'
import { Route } from 'react-router-dom'

import Start from 'components/templates/Calculator/Start'
import BeforeSteps from 'components/templates/Calculator/BeforeSteps'
import OnBoarding from 'components/templates/Calculator/OnBoarding'
import Steps from 'components/templates/Calculator/Steps'
import Calculator from 'components/templates/Calculator/Calculator'
import Recommendations from 'components/templates/Calculator/Recommendations'
import Final from 'components/templates/Final/Final'

const TriageRoute = () => (
  <>
    <Route exact path='/bienvenido' component={Start} />
    <Route exact path='/inicio' component={BeforeSteps} />
    <Route exact path='/onboarding' component={OnBoarding} />
    <Route exact path='/pasos' component={Steps} />
    <Route exact path='/calculadora' component={Calculator} />
    <Route exact path='/calculadora/:name' component={Recommendations} />
    <Route exact path='/finalizar' component={Final} />
  </>
)

export default TriageRoute
