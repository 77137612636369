import { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import firebase from 'utils/firebaseConfig'
import CardViewDate from 'components/atoms/CardView/CardViewDate'
import HeaderResponsive from 'components/molecules/HeaderResponsive'
import { AppContext } from 'context/AppContext'
import { formattedDate } from 'utils/helpers'

function HistorialInfoOne() {
  const { historySideEffects } = useContext(AppContext)

  useEffect(() => {
    firebase.analytics().logEvent('historial-adversos-dosis-dos')
  }, [])

  return (
    <>
      <div className='px-4 pt-3 px-lg-5 mx-lg-5'>
        <HeaderResponsive route='/historial-dosis' title='Dosis 2' />

        <>
          {_.toArray(historySideEffects).map(({ doses, date, effId }) => {
            if (doses === 2) {
              return (
                <div key={effId} className='ani-show'>
                  <CardViewDate title={formattedDate(date)} route={`dos/dosis-dos/${date}`} />
                </div>
              )
            }
            return false
          })}
        </>

        <div className='pt-lg-4 px-lg-0 d-none d-lg-block'>
          <div className='mt-lg-1'></div>
          <div className='px-lg-0 d-none d-lg-block'>
            <Link to='/historial-dosis' className='btn btn-outline-secondary'>
              Atrás
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default HistorialInfoOne
