const InputCheckbox = ({ name, description, handleChange, data }) => (
  <div className='wrapper d-flex justify-content-start align-items-center pb-3'>
    <input
      className='text-center custom-checkbox'
      type='checkbox'
      id={name}
      name={name}
      checked={data[name]}
      onChange={handleChange}
    />
    <label className='pt-1 message__conditions ml-3 pl-3 custom-label' htmlFor={name}>
      {description}
    </label>
  </div>
)

export default InputCheckbox
