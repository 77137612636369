import { useEffect, useContext } from 'react'

import Header from 'components/atoms/Header/Header'
import Step1 from 'components/organisms/Steps/Step1'
import Step2 from 'components/organisms/Steps/Step2'
import Step3 from 'components/organisms/Steps/Step3'
import { AppContext } from 'context/AppContext'
import firebase from 'utils/firebaseConfig'

function SideEffectsDosesOne() {
  const { step } = useContext(AppContext)

  useEffect(() => {
    firebase.analytics().logEvent('pasos-triage')
  }, [])

  const stepUp = (step) => {
    switch (step) {
      case 1:
        return <Step1 />

      case 2:
        return <Step2 />

      case 3:
        return <Step3 />

      default:
        return false
    }
  }

  return (
    <>
      <Header />
      {stepUp(step)}
    </>
  )
}

export default SideEffectsDosesOne
