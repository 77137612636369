import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Third from 'assets/stepper/Paso3.svg'
import LoaderShort from 'components/atoms/Loader/LoaderShort'
import InputCheckbox from 'components/atoms/Input/InputCheckbox'
import HeaderSideEffects from 'components/molecules/HeaderSideEffects'
import ErrorNetwork from 'components/organisms/Errors/ErrorNetwork'
import ErrorSession from 'components/organisms/Errors/ErrorSession'
import ErrorSystem from 'components/organisms/Errors/ErrorSystem'
import { AppContext } from 'context/AppContext'
import { sideEffects as sideEffectArray } from 'utils/conditions'
import { effectDosesOne } from 'utils/initialState'
import { request } from 'services/requestService'

function EffectsStep3() {
  const [isLoading, setIsLoading] = useState(false)
  const [modals, setModals] = useState({ errNetwork: false, errSession: false, errSystem: false })
  const { setStep, dosesOne, dosesTwo, sideEffectDoses, setSideEffectDoses, handleEffects } = useContext(AppContext)

  const history = useHistory()

  const handleSubmit = (e) => {
    e.preventDefault()

    const adverse = {
      ...sideEffectDoses,
      doses: dosesTwo ? 2 : 1,
      vacId: dosesTwo ? dosesTwo?.vacId : dosesOne?.vacId,
    }

    setIsLoading(true)

    request('post', 'adverse', adverse)
      .then(() => {
        setIsLoading(false)
        history.push('/finalizar-efectos')
      })
      .catch((error) => {
        setIsLoading(false)

        if (error.hasOwnProperty('response')) {
          if (error.response.status === 400) {
            setModals({ ...modals, errSession: true })
            return
          } else {
            setModals({ ...modals, errSystem: true })
            return
          }
        }
        if (error.hasOwnProperty('code')) {
          if (error.code === 'NetworkError') {
            setModals({ ...modals, errNetwork: true })
            return
          }
        }

        if (error === 'No current user') {
          //Error session
          setModals({ ...modals, errSession: true })
          history.push('/')
        }
      })
    setSideEffectDoses(effectDosesOne)
  }

  const propsModals = {
    modals,
    setModals,
  }

  if (isLoading) return <LoaderShort />

  return (
    <>
      <HeaderSideEffects image={Third} />
      <form onSubmit={handleSubmit}>
        <div className='px-4 px-lg-5 mx-lg-5'>
          {sideEffectArray.slice(8, 14).map(({ name, description }) => (
            <InputCheckbox
              name={name}
              description={description}
              handleChange={handleEffects}
              key={name}
              data={sideEffectDoses}
            />
          ))}
        </div>

        <div className='py-2 my-2'></div>

        <div className='d-none d-lg-block'>
          <div className='button__primary '>
            <hr className='mb-2 d-lg-none' />
            <div className='row px-4 px-lg-5 mx-lg-5'>
              <div className='col pr-lg-2 pl-lg-0'>
                <button to='/onboarding' className='btn btn-outline-secondary' type='button' onClick={() => setStep(2)}>
                  Atrás
                </button>
              </div>
              <div className='col pl-lg-2 pr-lg-0'>
                <button className='btn btn-primary' type='button' onClick={handleSubmit}>
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='fixed-bottom d-lg-none'>
          <div className='button__primary '>
            <hr className='mb-2 d-lg-none' />
            <div className='row px-4 px-lg-5 mx-lg-5'>
              <div className='col pr-lg-2 pl-lg-0'>
                <button to='/onboarding' className='btn btn-outline-secondary' onClick={() => setStep(2)}>
                  Atrás
                </button>
              </div>
              <div className='col pl-lg-2 pr-lg-0'>
                <button className='btn btn-primary' onClick={handleSubmit}>
                  Siguiente
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ErrorSystem {...propsModals} />
      <ErrorSession {...propsModals} />
      <ErrorNetwork {...propsModals} />
    </>
  )
}

export default EffectsStep3
