import { useState, useEffect, useContext } from 'react'
import moment from 'moment'

import VaccineRegisterForm from 'components/templates/Vaccine/VaccineRegisterForm'
import VaccineConfirmationForm from 'components/templates/Vaccine/VaccineConfirmationForm'
import RetakePictureFront from 'components/templates/Vaccine/Picture/RetakePictureFront'
import RetakePictureBack from 'components/templates/Vaccine/Picture/RetakePictureBack'
import firebase from 'utils/firebaseConfig'
import { vaccineOne } from 'utils/initialState'
import { AppContext } from 'context/AppContext'

function VaccineRegister() {
  const { step } = useContext(AppContext)
  const [vaccine, setVaccine] = useState(vaccineOne)
  const [vaccinationDate, setVaccinationDate] = useState('')

  const onChange = (date) => {
    setVaccinationDate(date)
    setVaccine({ ...vaccine, vaccinationDate: moment(vaccinationDate).format('YYYY-MM-DD') })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setVaccine({ ...vaccine, [name]: value })
  }

  const props = { vaccine, vaccinationDate, handleChange, onChange }

  useEffect(() => {
    firebase.analytics().logEvent('registro-vacuna-dosis-uno')
  }, [])

  useEffect(() => {
    if (!vaccinationDate) return
    setVaccine({ ...vaccine, vaccinationDate: moment(vaccinationDate).format('YYYY-MM-DD') })
  }, [setVaccine, vaccinationDate])

  const stepUp = (step) => {
    switch (step) {
      case 1:
        return <VaccineRegisterForm {...props} text='Dosis 1' />

      case 2:
        return <VaccineConfirmationForm {...props} text='Dosis 1' />

      case 3:
        return <RetakePictureFront route='/foto-dosis-uno' />

      case 4:
        return <RetakePictureBack route='/foto-dosis-uno' />

      default:
        return <VaccineRegisterForm {...props} text='Dosis 1' />
    }
  }

  return <>{stepUp(step)}</>
}

export default VaccineRegister
