import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'

import SelectCountry from 'components/atoms/SelectCountry'
import ButtonPrimaryPhone from 'components/atoms/Button/ButtonPrimaryPhone'
import countries from 'utils/countriesList'
import { getGeoData } from 'services/requestService'

function FormPhone({
  onSubmit,
  setPhone,
  countryCodeValue,
  setCountryCodeValue,
  isLoading,
  phone,
  seconds,
  timeOutRender,
}) {
  const { register, errors, handleSubmit } = useForm()
  const [flagImg, setFlagImg] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [browserLocation, setBrowserLocation] = useState({})
  const selectRef = useRef()

  /**
   * Find the country code that match with current user location
   * @param {Object} countries get the list of countries and find what is the current country
   * @returns {Object}
   */
  const findCodeData = (countries) => countries.code === countryCode
  const locationData = countries.find(findCodeData)

  const countryFlagHandler = () => {
    setPhone('')
    setFlagImg(
      `https://flagpedia.net/data/flags/h80/${selectRef.current.selectedOptions[0].dataset.countrycode.toLowerCase()}.webp`
    )
  }

  const selectCountryHandler = (e) => {
    setCountryCodeValue(e.target.value)
  }

  useEffect(() => {
    /** Set the current country code*/
    getGeoData().then((data) => {
      setCountryCode(data?.countryCode)
    })
    setBrowserLocation(locationData)
  }, [locationData])

  useEffect(() => {
    /** Set the current country flag and country dial code */
    setFlagImg(`https://flagpedia.net/data/flags/h80/${countryCode.toLowerCase()}.webp`)
    setCountryCodeValue(browserLocation?.dial_code)
  }, [countryCode, browserLocation])

  const props = { selectRef, selectCountryHandler, countryCodeValue }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='px-4 px-lg-5 mx-lg-5 pb-lg-5 pt-2 pt-lg-0'>
        <div className='tel-box'>
          <div>
            <label htmlFor='phoneNumber' className='form-label mb-2 mt-lg-0'>
              País
            </label>
            <div className='select-box' onChange={countryFlagHandler}>
              <img src={flagImg} alt='country-flag' id='img' className='flag-img' />
              <SelectCountry {...props} />
            </div>
          </div>
          <div className='ml-3 w-100'>
            <label htmlFor='phoneNumber' className='form-label mb-2 mt-lg-0'>
              N° de móvil
            </label>
            <input
              className={`form-control ${errors?.phoneNumber && 'form-control-error'} `}
              id='recaptcha-container'
              name='phoneNumber'
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              placeholder='Ej: 974xxxxxx'
              inputMode='numeric'
              type='number'
              pattern='[0-9]{4,12}'
              autoComplete='off'
              ref={register({
                required: {
                  value: true,
                  message: 'Debes ingresar tu número de móvil',
                },
                maxLength: {
                  value: 10,
                  message: 'Ingresa un número válido',
                },
                minLength: {
                  value: 9,
                  message: 'Ingresa un número válido',
                },
              })}
            />
          </div>
        </div>
        <span className='text-small d-block px-3 message__error'>{errors?.phoneNumber?.message}</span>
      </div>

      {timeOutRender && (
        <div className='text-left px-4'>
          <span className='message__loading ml-lg-4 pl-lg-5'>Podrá requerir un nuevo sms en {seconds} segundos</span>
        </div>
      )}
      {isLoading && (
        <>
          <span className='message__loading px-4 ml-lg-5 pl-lg-5'>Enviando mensaje...</span>
        </>
      )}
      <div className={timeOutRender || isLoading ? 'login__padding--message' : 'login__padding'}></div>

      <ButtonPrimaryPhone phone={phone} />
    </form>
  )
}

export default FormPhone
