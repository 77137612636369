import { useState, useContext } from 'react'
import moment from 'moment'

import Virus from 'assets/light/virus.svg'
import Header from 'components/atoms/Header/Header'
import ImageHero from 'components/atoms/ImageHero'
import Loading from 'components/atoms/Loader/Loading'
import ErrorNetwork from 'components/organisms/Errors/ErrorNetwork'
import ErrorSession from 'components/organisms/Errors/ErrorSession'
import ErrorSystem from 'components/organisms/Errors/ErrorSystem'
import ErrorDate from 'components/organisms/Errors/ErrorDate'
import firebase from 'utils/firebaseConfig'
import { AppContext } from 'context/AppContext'
import { request } from 'services/requestService'
import { findDate } from 'utils/helpers'

function ValidationSideEffects({ history }) {
  const { dosesOne, dosesTwo, sideEffectDoses, setSideEffectDoses, historySideEffects } = useContext(AppContext)

  const [isLoading, setIsLoading] = useState(false)
  const [modals, setModals] = useState({ errNetwork: false, errSession: false, errSystem: false, errDate: false })
  const date = moment().format('YYYY-MM-DD')

  const symptoms = findDate(historySideEffects, date)

  const captureEffects = () => {
    setSideEffectDoses({
      ...sideEffectDoses,
      date,
    })
    sideEffectDoses.date = date
  }

  const withEffects = () => {
    /** If we don't have any symptom registered this day continue the flow with side effects */
    if (!symptoms) {
      captureEffects()
      history.push('/reacciones-onboarding')
      firebase.analytics().logEvent('si-secundarios-vacuna')
    } else {
      setModals({ ...modals, errDate: true })
    }
  }

  const effectsNull = () => {
    captureEffects()

    const adverse = {
      ...sideEffectDoses,
      doses: dosesTwo ? 2 : 1,
      vacId: dosesTwo ? dosesTwo?.vacId : dosesOne?.vacId,
    }

    /** If we don't have any symptom registered this day continue the flow without side effects */
    if (!symptoms) {
      setIsLoading(true)
      request('post', 'adverse', adverse)
        .then(() => {
          setIsLoading(false)
          history.push('/finalizar-efectos')
          firebase.analytics().logEvent('no-secundarios-vacuna')
        })
        .catch((error) => {
          setIsLoading(false)
          if (error.hasOwnProperty('response')) {
            if (error.response.status === 400) {
              setModals({ ...modals, errSession: true })
              return
            } else {
              setModals({ ...modals, errSystem: true })
              return
            }
          }
          if (error === 'No current user') {
            //Error session
            setModals({ ...modals, errSession: true })
            history.push('/')
            return
          }
        })
    } else {
      setModals({ ...modals, errDate: true })
    }
  }

  const propsModals = {
    modals,
    setModals,
  }

  if (isLoading) return <Loading />

  return (
    <>
      <Header />
      <div className='pb-3'></div>
      <ImageHero image={Virus} alt='doctor con una paciente' />
      <div className='paragraph text-center px-4 px-lg-5 mx-lg-5'>
        <div className='py-lg-3'>
          <h2 className='py-3 pt-3 my-4 py-lg-0 py-lg-3'>¿Tuviste alguna reacción adversa?</h2>
        </div>
      </div>

      <div className='paragraph text-center px-4 px-lg-5 mx-lg-5'></div>
      <div className='pt-2'></div>
      <div className='py-lg-4 my-2'></div>

      <div className='d-none d-lg-block'>
        <div className='button__primary'>
          <hr className='mb-2 d-lg-none' />
          <div className='row px-4 px-lg-5 mx-lg-5'>
            <div className='col pl-lg-0 pr-lg-2'>
              <button className='btn btn-outline-secondary' onClick={effectsNull}>
                No
              </button>
            </div>
            <div className='col pl-lg-2 pr-lg-0'>
              <button className='btn btn-primary' onClick={withEffects}>
                Si
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='py-5 py-lg-0'></div>

      <div className='fixed-bottom d-lg-none'>
        <div className='button__primary'>
          <hr className='mb-2 d-lg-none' />
          <div className='row px-4 px-lg-5 mx-lg-5'>
            <div className='col pl-lg-0 pr-lg-2'>
              <button className='btn btn-outline-secondary' onClick={effectsNull}>
                No
              </button>
            </div>
            <div className='col pl-lg-2 pr-lg-0'>
              <button className='btn btn-primary' onClick={withEffects}>
                Si
              </button>
            </div>
          </div>
        </div>
      </div>
      <ErrorDate {...propsModals} />
      <ErrorSystem {...propsModals} />
      <ErrorSession {...propsModals} />
      <ErrorNetwork {...propsModals} />
    </>
  )
}

export default ValidationSideEffects
