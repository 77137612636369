import React from 'react'
import { Image, Card } from 'react-bootstrap'

import Info from 'assets/icons/information.svg'

import './CardInfo.scss'

const CardInfo = ({ description }) => (
  <div className='card__info pb-4 pb-lg-3 mb-2'>
    <Card.Body className='d-flex p-0'>
      <Image src={Info} alt='icono de información' className='pl-2' />
      <Card.Text className='p-2'>{description}</Card.Text>
    </Card.Body>
  </div>
)

export default CardInfo
