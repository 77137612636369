import firebase from 'firebase'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyBEI_cRiAhGXE-sugg4t1J5KxG3YvL3Zac',
  authDomain: 'vacunate-prod.firebaseapp.com',
  projectId: 'vacunate-prod',
  storageBucket: 'vacunate-prod.appspot.com',
  messagingSenderId: '176519965398',
  appId: '1:176519965398:web:5b86b7c94af8997ecdb777',
  measurementId: 'G-LLZQWDYY24',
}

firebase.initializeApp(firebaseConfig)

export default firebase
