import React from 'react'
import { Link } from 'react-router-dom'
import './ButtonPrimary.scss'

const ButtonPrimary = ({ button, route, padding = '30px' }) => (
  <div className='button__primary' style={{ paddingTop: padding }}>
    <hr className='mb-2 d-lg-none' />
    <div className='px-4 px-lg-5 mx-lg-5'>
      <Link to={route} className='btn btn-block btn-primary'>
        {button}
      </Link>
    </div>
  </div>
)

export default ButtonPrimary
