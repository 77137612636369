import { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/es'

import { AppContext } from 'context/AppContext'
import DosesHeader from 'components/molecules/DosesHeader'
import Calendar from 'assets/icons/calendar-black.svg'
import Factory from 'assets/icons/factory.svg'
import Box from 'assets/icons/box.svg'
import Location from 'assets/icons/location.svg'
import Doctor from 'assets/icons/doctor.svg'
import ButtonTakePicture from 'components/atoms/Button/ButtonTakePicture'
import firebase from 'utils/firebaseConfig'

function DosesRoute({ history }) {
  const { dosesTwo } = useContext(AppContext)

  const viewFront = () => {
    history.push('/foto-frontal-dosis-dos')
  }

  const viewBack = () => {
    history.push('/foto-reverso-dosis-dos')
  }

  useEffect(() => {
    firebase.analytics().logEvent('informacion-dosis-dos')
  }, [])

  return (
    <>
      <div className='px-4 pt-3 pt-lg-0 px-lg-5 mx-lg-5'>
        <DosesHeader text='Dosis 2' />

        <div className=' pt-3 mb-3 pb-2'>
          <h5 className='text-left message__doses-info '>Imágenes de la cartilla de vacunación</h5>
        </div>

        <ButtonTakePicture title='Frente de la cartilla' route='#' click={viewFront} />
        <ButtonTakePicture title='Dorso de la cartilla' route='#' click={viewBack} />

        <div className=' pt-lg-2 pt-3 mb-3 pb-2'>
          <h5 className='text-left message__doses-info '>Datos de registro de vacuna</h5>
        </div>

        <div className='ani-show'>
          <h6 className='message__doses mt-0'>Fecha</h6>
          <div className='d-flex align-items-center message__block'>
            <img src={Calendar} alt='icono de calendario' />
            <p className='message__vaccine pl-2'>{moment(dosesTwo?.vaccinationDate).format('LL')}</p>
          </div>

          <h6 className='message__doses mt-0'>Nombre del fabricante </h6>
          <div className='d-flex align-items-center message__block'>
            <img src={Factory} alt='icono de tienda' />
            <p className='message__vaccine pl-2'>{_.startCase(_.toLower(dosesTwo?.manufacturer))}</p>
          </div>

          <h6 className='message__doses mt-0'>Lote</h6>
          <div className='d-flex align-items-center message__block'>
            <img src={Box} alt='icono de caja' />
            <p className='message__vaccine pl-2'>
              {dosesTwo?.lote ? dosesTwo?.lote.toUpperCase() : 'Sin información registrada'}
            </p>
          </div>

          <h6 className='message__doses mt-0'>Centro vacunador</h6>
          <div className='d-flex align-items-center message__block'>
            <img src={Location} alt='icono de ubicacion' />
            <p className='message__vaccine pl-3'>
              {dosesTwo?.vaccineCenter ? _.startCase(_.toLower(dosesTwo?.vaccineCenter)) : 'Sin información registrada'}
            </p>
          </div>

          <h6 className='message__doses mt-0'>Nombre del vacunador</h6>
          <div className='d-flex align-items-center message__block'>
            <img src={Doctor} alt='icono de doctor' />
            <p className='message__vaccine pl-2'>
              {dosesTwo?.vaccinatorName
                ? _.startCase(_.toLower(dosesTwo?.vaccinatorName))
                : 'Sin información registrada'}
            </p>
          </div>

          <h6 className='message__doses mt-0'>Cédula del vacunador</h6>
          <div className='d-flex align-items-center message__block'>
            <p className='message__vaccine pl-4 ml-1'>
              {dosesTwo?.vaccinatorId ? dosesTwo?.vaccinatorId : 'Sin información registrada'}
            </p>
          </div>
        </div>

        <div className='py-1'></div>
        <div className='py-4 mt-3 d-none d-lg-block'>
          <div className='mt-lg-1'></div>
          <div className='px-lg-0 d-none d-lg-block'>
            <Link to='/dosis' className='btn btn-outline-secondary'>
              Atrás
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default DosesRoute
