const imageSrc = {
  front: {
    blob: null,
    url: null,
    type: null,
  },
  back: {
    blob: null,
    url: null,
    type: null,
  },
}
export const setImage = (imgKey, img, blob) => {
  imageSrc[imgKey].blob = blob
  imageSrc[imgKey].url = URL.createObjectURL(img)
  imageSrc[imgKey].type = img.type
}

export const getImage = () => {
  return imageSrc
}

export const clearData = () => {
  imageSrc.front.blob = null
  imageSrc.front.url = null
  imageSrc.front.type = null

  imageSrc.back.blob = null
  imageSrc.back.url = null
  imageSrc.back.type = null
}
