import React from 'react'
import { Card } from 'react-bootstrap'

import './CardFase.scss'

const CardFase = ({ userData, age }) => {
  const { essentialJob, pregnancy, hypertension, pulmonary, immunosuppressive } = userData

  const Job = (essentialJob && !pregnancy) || age >= 60 ? 'FASE 1' : ''
  const JobParagraph = (essentialJob && !pregnancy) || age >= 60 ? 'Se recomienda vacunarse' : ''

  const JobAndPregnant = essentialJob || pregnancy ? 'FASE 1' : ''
  const JobAndPregnantParagraph = essentialJob || pregnancy ? 'Se recomienda vacunarse bajo recomendación médica' : ''

  const older = hypertension || pulmonary ? 'FASE 2' : ''
  const olderParagraph = hypertension || pulmonary ? 'Se recomienda vacunarse' : ''

  const younger = (age < 60 || immunosuppressive || !pregnancy) && !essentialJob ? 'NO BAJES LA GUARDIA' : ''
  const youngerParagraph =
    (age < 60 || immunosuppressive || !pregnancy) && !essentialJob
      ? 'Aún no ingresas en el esquema de vacunación nacional'
      : ''

  return (
    <Card className='text-center card__fase p-4'>
      <Card.Title className='pb-1 mb-0'>{Job || JobAndPregnant || older || younger}</Card.Title>
      <Card.Text>{JobParagraph || JobAndPregnantParagraph || olderParagraph || youngerParagraph}</Card.Text>
    </Card>
  )
}

export default CardFase
