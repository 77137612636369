import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'

import ArrowBack from 'assets/icons/arrowLeft.svg'
import LogoTerms from 'components/atoms/LogoTerms'
import ListSymptoms from 'components/molecules/ListSymptoms'
import { findDate, formattedDate } from 'utils/helpers'
import { AppContext } from 'context/AppContext'

function HistorialDetail() {
  const { historySideEffects } = useContext(AppContext)
  const { dosis, date } = useParams()
  const route = dosis === 'dosis-uno' ? '/historial-dosis-uno' : '/historial-dosis-dos'

  const symptoms = findDate(historySideEffects, date)

  const {
    cough,
    chills,
    fatigue,
    headache,
    fever,
    musclePain,
    swollenGlands,
    injectionInflammation,
    sickness,
    vomit,
    dizziness,
    decreasedAppetite,
    diarrhea,
  } = symptoms

  const anySymptom =
    cough ||
    chills ||
    fatigue ||
    headache ||
    fever ||
    musclePain ||
    swollenGlands ||
    injectionInflammation ||
    sickness ||
    vomit ||
    dizziness ||
    decreasedAppetite ||
    diarrhea

  return (
    <>
      <div className='px-4 pt-3'>
        <LogoTerms />
        <div className='row px-3 pb-4 d-flex align-items-center mb-lg-2'>
          <div className='col-2 ml-0 pl-0 d-lg-none'>
            <Link to={route}>
              <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
            </Link>
          </div>
          <div className='col-8 col-lg-12 conditions__title'>
            <h5 className='text-center mb-0'>{formattedDate(date)}</h5>
          </div>
        </div>

        <h5 className='text-left message__doses-info mb-0 px-lg-5 ml-lg-3'>
          {dosis === 'dosis-uno' ? 'Dosis 1' : 'Dosis 2'}
        </h5>

        <div className='conditions__container pt-4 mt-lg-2 px-lg-5 mx-lg-3'>
          {!anySymptom ? (
            <div className='message__conditions'>
              <p className='text-center message__final pb-4 mt-1'>
                No tienes registrado reacciones
                <br />
                adversas en esta fecha.
              </p>
            </div>
          ) : (
            <ListSymptoms symptoms={symptoms} />
          )}
        </div>
      </div>
      <div className='pt-lg-5 px-lg-5 d-none d-lg-block mx-lg-5'>
        <Link to={route} className='btn btn-outline-secondary'>
          Atrás
        </Link>
      </div>
    </>
  )
}

export default HistorialDetail
