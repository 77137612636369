import React from 'react'
import { Image } from 'react-bootstrap'

const ImageError = ({ image, alt }) => {
  return (
    <div className='image__hero text-center pt-2'>
      <Image style={{ width: 120, maxHeight: 224}} src={image} alt={alt} fluid />
    </div>
  )
}

export default ImageError