import React from 'react'
import { Link } from 'react-router-dom'

import Exam from 'assets/icons/exam-dark.svg'
import ArrowRight from 'assets/icons/arrowRightDisabled.svg'

const CardViewDate = ({ title, route }) => (
  <Link className='recommend' to={`/historial-dosis-${route}`}>
    <div className='card__view d-flex justify-content-around align-items-center mb-3'>
      <img src={Exam} alt='icono de información' className='info__icon' />
      <p className='py-4 mb-0'>{title}</p>
      <img src={ArrowRight} alt='flecha apuntando hacia la derecha' />
    </div>
  </Link>
)

export default CardViewDate
