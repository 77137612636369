import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import Third from 'assets/stepper/Paso3.svg'
import InputCheckbox from 'components/atoms/Input/InputCheckbox'
import Title from 'components/atoms/Title/Title'
import CardInfo from 'components/atoms/CardInfo/CardInfo'
import ErrorNetwork from 'components/organisms/Errors/ErrorNetwork'
import ErrorSession from 'components/organisms/Errors/ErrorSession'
import ErrorSystem from 'components/organisms/Errors/ErrorSystem'
import { AppContext } from 'context/AppContext'
import { calculateRisk } from 'utils/helpers'
import { conditions } from 'utils/conditions'
import { request } from 'services/requestService'

function Step3() {
  const { user, setStep, userData, setUserData, handleChange } = useContext(AppContext)

  const history = useHistory()
  const [risk, setRisk] = useState(0)
  const factorRisk = calculateRisk(user, userData)
  const [modals, setModals] = useState({ errNetwork: false, errSession: false, errSystem: false })

  const propsModals = {
    modals,
    setModals,
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setUserData({ ...userData, factorRisk: +risk })

    /**
     * @typedef {Object} data
     * @property {Number} factorRisk the factor risk value
     * @property {Number} documentNumber user id
     *
     */

    /**
     * @type {Object}
     */

    const data = { ...userData, factorRisk: +risk, documentNumber: user.documentNumber }

    request('post', 'triage', data)
      .then(() => {
        setStep(1)
        history.push('/calculadora')
      })
      .catch((error) => {
        if (error.hasOwnProperty('response')) {
          if (error.response.status === 401) {
            setModals({ ...modals, errSession: true })
            return
          } else {
            setModals({ ...modals, errSystem: true })
            return
          }
        }
        if (error.hasOwnProperty('code')) {
          if (error.code === 'NetworkError') {
            setModals({ ...modals, errNetwork: true })
            return
          }
        }

        if (error === 'No current user') {
          //Error session
          setModals({ ...modals, errSession: true })
          history.push('/')
          return
        }
      })
  }

  useEffect(() => {
    setRisk(factorRisk)
  }, [factorRisk])

  return (
    <>
      <div className='mb-3 mb-lg-4 text-center'>
        <img src={Third} alt='imagen paso tres de tres' />
      </div>
      <Title title='Antecedentes médicos' />
      <div className='px-4 px-lg-5 mx-lg-5'>
        <CardInfo
          description='Los antecedentes médicos son una recopilación de la información sobre la salud de una persona: patologías
          previas, vacunas recibidas, embarazos, hábitos de alimentación, etc'
        />
      </div>
      <form>
        <div className='px-4 px-lg-5 mx-lg-5'>
          {conditions.map(({ name, description }) => (
            <InputCheckbox
              name={name}
              description={description}
              handleChange={handleChange}
              key={name}
              data={userData}
            />
          ))}
        </div>
        <div className='button__primary '>
          <hr className='mb-2 d-lg-none' />
          <div className='row px-4 px-lg-5 mx-lg-5'>
            <div className='col pr-lg-2 pl-lg-0'>
              <button to='/onboarding' className='btn btn-outline-secondary' type='button' onClick={() => setStep(2)}>
                Atrás
              </button>
            </div>
            <div className='col pl-lg-2 pr-lg-0'>
              <button className='btn btn-primary' type='button' onClick={handleSubmit}>
                Continuar
              </button>
            </div>
          </div>
        </div>
      </form>
      <ErrorSystem {...propsModals} />
      <ErrorSession {...propsModals} />
      <ErrorNetwork {...propsModals} />
    </>
  )
}

export default Step3
