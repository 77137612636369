import { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import HeaderResponsive from 'components/molecules/HeaderResponsive'
import CardDoses from 'components/atoms/CardHome/CardDoses'
import Calendar from 'assets/light/calendar_icon.svg'
import { AppContext } from 'context/AppContext'

const DosesSideEffect = ({ history }) => {
  const { dosesOne, dosesTwo, getUrl } = useContext(AppContext)

  useEffect(() => {
    if (getUrl === 'sms') {
      history.push('/que-son-efectos-adversos')
    }
  }, []) // eslint-disable-line

  return (
    <div className='px-4 pt-1 px-lg-5 mx-lg-5'>
      <HeaderResponsive route='nueva-reaccion-adversa' title='Reacciones adversas' />
      <div className='pt-2'></div>
      <CardDoses
        icon={Calendar}
        title='Dosis 1'
        route='/que-son-efectos-adversos'
        alt='icono de calendario'
        doses={+1}
        vacId={dosesOne?.vacId}
        disabled={dosesTwo?.vaccinationDate ? true : ''}
      />
      <CardDoses
        icon={Calendar}
        title='Dosis 2'
        alt='icono de calendario'
        route='/que-son-efectos-adversos'
        doses={+2}
        vacId={dosesTwo?.vacId}
        disabled={dosesTwo?.vaccinationDate ? '' : true}
      />
      <div className='pt-lg-5 px-lg-0 d-none d-lg-block'>
        <div className='mt-lg-1'></div>
      </div>

      <div className='py-lg-5 px-lg-0 d-none d-lg-block '>
        <div className='py-lg-5'></div>
        <Link to='/nueva-reaccion-adversa' className='btn btn-outline-secondary'>
          Atrás
        </Link>
      </div>
    </div>
  )
}

export default DosesSideEffect
