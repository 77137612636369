import { useContext } from 'react'
import { AppContext } from 'context/AppContext'

import First from 'assets/stepper/Paso1.svg'
import InputCheckbox from 'components/atoms/Input/InputCheckbox'
import ButtonSubmit from 'components/atoms/Button/ButtonSubmit'
import HeaderSideEffects from 'components/molecules/HeaderSideEffects'
import { sideEffects as sideEffectArray } from 'utils/conditions'

function EffectsStep1() {
  const { setStep, sideEffectDoses, handleEffects } = useContext(AppContext)

  const handleSubmit = (e) => {
    e.preventDefault()
    setStep(2)
  }

  return (
    <>
      <HeaderSideEffects image={First} />
      <form onSubmit={handleSubmit}>
        <div className='px-4 px-lg-5 mx-lg-5'>
          {sideEffectArray.slice(0, 5).map(({ name, description }) => (
            <InputCheckbox
              name={name}
              description={description}
              handleChange={handleEffects}
              key={name}
              data={sideEffectDoses}
            />
          ))}
        </div>

        <div className='pb-3'></div>
        <div className='d-none d-lg-block'>
          <ButtonSubmit text='Siguiente' />
        </div>

        <div className='fixed-bottom d-lg-none'>
          <ButtonSubmit text='Siguiente' padding='0' />
        </div>
      </form>
    </>
  )
}

export default EffectsStep1
