export const users = {
  name: '',
  lastName: '',
  birthDate: '',
  email: '',
  gender: '',
  phoneNumber: '',
  termsAccepted: false,
  typeId: 'dni',
  documentNumber: '',
  newsletter: false,
}

export const triage = {
  weight: '',
  essentialJob: false,
  height: '',
  hypertension: false,
  pregnancy: false,
  diabetes: false,
  pulmonary: false,
  renal: false,
  immunosuppressive: false,
}

export const vaccineOne = {
  doses: 1,
  manufacturer: '',
  vaccineCenter: '',
  vaccinationDate: '',
  vaccinatorName: '',
  vaccinatorId: '',
  lote: '',
}

export const vaccineTwo = {
  doses: 2,
  manufacturer: '',
  vaccineCenter: '',
  vaccinationDate: '',
  vaccinatorName: '',
  vaccinatorId: '',
  lote: '',
}

export const effectDosesOne = {
  fatigue: false,
  headache: false,
  fever: false,
  musclePain: false,
  chills: false,
  cough: false,
  swollenGlands: false,
  injectionInflammation: false,
  sickness: false,
  vomit: false,
  dizziness: false,
  decreasedAppetite: false,
  diarrhea: false,
}
