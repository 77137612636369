import { useContext } from 'react'
import { Link } from 'react-router-dom'

import ArrowBack from 'assets/icons/arrowLeft.svg'
import LogoTerms from 'components/atoms/LogoTerms'
import { AppContext } from 'context/AppContext'

function PictureFront({ route }) {
  const { previewFrontal, setStep } = useContext(AppContext)

  const takeAgain = () => {
    setStep(1)
  }

  return (
    <>
      <div className='pt-lg-3'></div>
      <LogoTerms />
      <div className='px-4 pt-3 pt-lg-0'>
        <div className='row px-3 pb-3 d-flex align-items-center mb-lg-3'>
          <div className='col-2 ml-0 pl-0 d-lg-none'>
            <Link to='#' onClick={() => setStep(2)}>
              <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
            </Link>
          </div>
          <div className='col-8 col-lg-12 conditions__title'>
            <h5 className='text-center mb-0'>Frente de la cartilla</h5>
          </div>
        </div>

        <div className='d-none d-lg-block text-center image-input ani-show'>
          <img src={previewFrontal} alt='foto frontal imagen' />
        </div>

        <div className='d-lg-none text-center px-4 image-input ani-show'>
          <img src={previewFrontal} alt='foto frontal imagen' />
        </div>
      </div>

      <div className='pt-2 mt-1'>
        <div className='button__primary'>
          <hr className='mb-2 d-lg-none' />
          <div className='row px-4 px-lg-5 mx-lg-5'>
            <div className='col px-lg-0 d-none d-lg-block pl-lg-0 pr-lg-2'>
              <button className='btn btn-outline-secondary' onClick={() => setStep(2)}>
                Atrás
              </button>
            </div>
            <div className='col pl-lg-2 pr-lg-0'>
              <Link to={route} className='btn btn-primary d-none d-lg-block' onClick={takeAgain}>
                Adjuntar de nuevo
              </Link>
              <Link to={route} className='btn btn-outline-secondary  d-lg-none' onClick={takeAgain}>
                Adjuntar de nuevo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PictureFront
