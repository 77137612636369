import Virus from 'assets/icons/virus-icon.svg'

const ElementSideEffects = ({ title }) => {
  return (
    <>
      <div className='d-flex justify-content-start align-items-center pb-3 conditions' key='1'>
        <img src={Virus} alt='imagen de resultados' />
        <p className='ml-2 mb-0 pb-0'>{title}</p>
      </div>
    </>
  )
}

export default ElementSideEffects
