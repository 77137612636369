import Second from 'assets/stepper/2-step.svg'
import Header from 'components/atoms/Header/Header'
import HeaderSteps from 'components/molecules/HeaderSteps'
import FormVaccine from 'components/organisms/VaccineForm/FormVaccine'

const VaccineRegisterForm = ({ vaccine, vaccinationDate, onChange, handleChange, text }) => (
  <>
    <Header />
    <HeaderSteps image={Second} subtitle='Te solicitamos que completes los campos obligatorios del registro.' />
    <FormVaccine
      vaccine={vaccine}
      vaccinationDate={vaccinationDate}
      onChange={onChange}
      handleChange={handleChange}
      text={text}
    />
  </>
)

export default VaccineRegisterForm
