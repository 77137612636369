import { Link } from 'react-router-dom'

import ArrowBack from 'assets/icons/arrowLeft.svg'
import Header from 'components/atoms/Header/Header'

const DosesHeader = ({ text }) => (
  <>
    <div className='d-none d-lg-block'>
      <Header />
    </div>
    <div className='row px-3 pb-4 d-flex align-items-center'>
      <div className='col-2 ml-0 pl-0 d-lg-none'>
        <Link to='/dosis'>
          <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
        </Link>
      </div>

      <div className='col-8 col-lg-12 conditions__title p-0'>
        <h5 className='text-center mb-0'>{text}</h5>
      </div>
    </div>
  </>
)

export default DosesHeader
