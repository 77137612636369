import './style/style.scss'
import AppRouter from './routes/AppRouter'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'

import Amplify from 'aws-amplify'
import awsconfig from './aws_exports'
Amplify.configure(awsconfig)

function App() {
  return (
    <Container>
      <div className='col-lg-8 mx-auto h-100 container__app'>
        <AppRouter />
      </div>
    </Container>
  )
}

export default App
