import { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import Calendar from 'assets/light/calendar_icon.svg'
import CardHome from 'components/atoms/CardHome/CardHome'
import HeaderResponsive from 'components/molecules/HeaderResponsive'
import firebase from 'utils/firebaseConfig'
import { AppContext } from 'context/AppContext'

function DosesHistorial() {
  const { historySideEffects } = useContext(AppContext)

  /** Search doses two side effect to enable route*/
  const searchTwo = (historySideEffects) => historySideEffects.doses === 2
  const enableDoseTwo = historySideEffects.find(searchTwo)

  useEffect(() => {
    firebase.analytics().logEvent('historial-efectos-adversos')
  }, [])

  return (
    <div className='px-4 pt-1 px-lg-5 mx-lg-5'>
      <HeaderResponsive route='nueva-reaccion-adversa' title='Historial de reacciones' />
      <div className='pt-2'></div>
      <CardHome icon={Calendar} title='Dosis 1' route='/historial-dosis-uno' alt='icono de calendario' />
      <CardHome
        icon={Calendar}
        title='Dosis 2'
        alt='icono de calendario'
        route='/historial-dosis-dos'
        disabled={enableDoseTwo ? '' : true}
      />
      <div className='pt-lg-5 px-lg-0 d-none d-lg-block'>
        <div className='mt-lg-1'></div>
      </div>

      <div className='py-lg-5 px-lg-0 d-none d-lg-block '>
        <div className='py-lg-5'></div>
        <Link to='/nueva-reaccion-adversa' className='btn btn-outline-secondary'>
          Atrás
        </Link>
      </div>
    </div>
  )
}

export default DosesHistorial
