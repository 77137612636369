import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import es from 'date-fns/locale/es'

import ButtonRegister from 'components/atoms/Button/ButtonRegister'
import CustomDatePicker from 'components/atoms/Input/CustomDatePicker'
import { userSchema } from 'Validations/SchemaValidation'
registerLocale('es', es)

function FormUser({ user, userBirthDate, onChange, handleUser, handleChange, dateValidation, errorDate }) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(userSchema),
  })

  return (
    <form onSubmit={handleSubmit(handleUser)}>
      <div className='px-4'>
        <div className='row'>
          <div className='col-sm-12 col-lg-6'>
            <label htmlFor='name' className='form-label mb-0 mt-lg-0'>
              Nombres
            </label>
            <input
              className={`form-control ${errors?.name && 'form-control-error'} `}
              type='text'
              name='name'
              value={user.name}
              placeholder='Ingresa tus nombres'
              onChange={handleChange}
              ref={register}
            />
            <span
              className={`${errors?.name ? 'message__error' : 'text-white'} text-small d-block pl-3 message__error`}
            >
              Debes ingresar tus nombres
            </span>
          </div>

          <div className='col-sm-12 col-lg-6'>
            <label htmlFor='lastName' className='form-label mb-0 mt-lg-0'>
              Apellidos
            </label>
            <input
              className={`form-control ${errors?.lastName && 'form-control-error'} `}
              name='lastName'
              value={user.lastName}
              placeholder='Ingresa tus apellidos'
              onChange={handleChange}
              ref={register}
            />
            <span className={`${errors?.lastName ? 'message__error' : 'text-white'} text-small d-block pl-3`}>
              Debes ingresar tus apellidos
            </span>
          </div>
        </div>

        <div className='row'>
          <div className='col col-lg-3'>
            <label className='form-label mb-0' htmlFor='typeId'>
              Tipo de documento
            </label>
            <select
              className={`form-control ${errors?.documentNumber && 'form-control-error'} `}
              name='typeId'
              value={user.typeId}
              onChange={handleChange}
              ref={register}
            >
              <option value='dni'>DNI</option>
              <option value='pasaporte'>Pasaporte</option>
              <option value='extranjeria'>Cedula de Extranjería</option>
            </select>
          </div>
          <div className='col col-lg-3'>
            <label className='form-label mb-0' htmlFor='documentNumber'>
              N° de documento
            </label>
            <input
              className={`form-control ${errors?.documentNumber && 'form-control-error'} `}
              name='documentNumber'
              value={user.documentNumber}
              onChange={handleChange}
              placeholder='Ej: 11122233'
              inputMode='numeric'
              pattern='[0-9]{4,12}'
              minLength='6'
              ref={register}
            />
            <span
              className={`${errors?.documentNumber?.message ? 'message__error' : 'text-white'} text-small d-block pl-2`}
            >
              Ingresa el número
            </span>
          </div>

          <div className='col-sm-12 col-lg-6'>
            <label className='form-label mb-0' htmlFor='birthDate'>
              Fecha de nacimiento
            </label>
            <DatePicker
              type='date'
              selected={userBirthDate}
              value={moment(user?.birthDate).format('L')}
              onChange={onChange}
              locale='es'
              dateFormat='dd/MM/yyyy'
              placeholderText='dd/mm/aaaa'
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              minDate={new Date('1900')}
              maxDate={new Date()}
              customInput={<CustomDatePicker errorDate={errorDate} />}
            />
            <span className={`${errorDate ? 'message__error' : 'text-white'} text-small d-block pl-2`}>
              Ingresa fecha válida
            </span>
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 col-lg-6'>
            <label className='form-label mb-0' htmlFor='gender'>
              Género
            </label>
            <select
              className={`form-control ${errors?.gender && 'form-control-error'} `}
              name='gender'
              value={user.gender}
              onChange={handleChange}
              ref={register}
            >
              <option value='' disabled>
                Selecciona género
              </option>
              <option value='femenino'>Femenino</option>
              <option value='masculino'>Masculino</option>
            </select>
            <span className={`${errors?.gender?.message ? 'message__error' : 'text-white'} text-small d-block pl-3`}>
              Debes seleccionar género
            </span>
          </div>

          <div className='col-sm-12 col-lg-6'>
            <label className='form-label mb-0 ' htmlFor='email'>
              Correo electrónico
            </label>
            <input
              className={`form-control ${errors?.email && 'form-control-error'} `}
              type='email'
              value={user.email}
              name='email'
              placeholder='Ingresa tu correo electrónico'
              onChange={handleChange}
              ref={register}
            />
            <span className={`${errors?.email ? 'message__error' : 'text-white'} text-small d-block pl-3`}>
              Por favor, ingresa tu correo electrónico
            </span>
          </div>
        </div>

        <div className='pt-3 my-lg-3'>
          <div className='wrapper d-flex justify-content-start align-items-center'>
            <input
              className='text-center custom-input d-flex align-items-center'
              id='newsletter'
              type='checkbox'
              name='newsletter'
              checked={user['newsletter']}
              onChange={handleChange}
            />
            <label htmlFor='newsletter' className='message__terms ml-3 pl-3 custom-label'>
              Quiero suscribirme al boletín para que llegue a mi correo electrónico
            </label>
          </div>
        </div>

        <div className='pt-3 my-lg-3'>
          <div className='wrapper d-flex justify-content-start align-items-center'>
            <input
              className='text-center custom-input d-flex align-items-center'
              id='termsChecked'
              type='checkbox'
              name='termsAccepted'
              checked={user['termsAccepted']}
              onChange={handleChange}
            />
            <label htmlFor='termsChecked' className='message__terms ml-3 pl-3 custom-label'>
              Acepto los
              <span className='px-1'>
                <Link className='redirect' to='/terminos-pais'>
                  Términos y Condiciones
                </Link>
              </span>
              y la
              <span className='px-1'>
                <Link className='redirect' to='/privacidad-pais'>
                  Política de Privacidad
                </Link>
              </span>
            </label>
          </div>
        </div>
      </div>
      <ButtonRegister user={user} dateValidation={dateValidation} />
    </form>
  )
}

export default FormUser
