import React, { useContext } from 'react'

import Two from 'assets/stepper/Paso2.svg'
import Shield from 'assets/icons/shield-check.svg'
import Title from 'components/atoms/Title/Title'
import Toggle from 'components/atoms/Input/Toggle'
import { AppContext } from 'context/AppContext'
import { essential } from 'utils/essentialJobs.json'

const Step2 = () => {
  const { setStep, userData, handleChange } = useContext(AppContext)

  return (
    <>
      <div className='mb-3 mb-lg-4 text-center'>
        <img src={Two} alt='imagen paso dos de tres' />
      </div>
      <div className='pb-lg-0'>
        <Title title='Trabajador esencial' />
      </div>

      <div className='px-4 px-lg-5 mx-lg-5'>
        <Toggle userData={userData} handleChange={handleChange} />

        <h5 className='text__subtitle pb-3'>Involucra las siguientes categorías</h5>
        {essential.map((job) => (
          <div className='jobs__list essential__text' key={job}>
            <img src={Shield} alt='escucho con check' />
            <p className='essential__paragraph '>{job}</p>
          </div>
        ))}
      </div>

      <div className='pt-lg-2'></div>
      <div className='button__primary'>
        <hr className='mb-2 d-lg-none' />
        <div className='row px-4 px-lg-5 mx-lg-5'>
          <div className='col pl-lg-0 pr-lg-2'>
            <button to='/onboarding' className='btn btn-outline-secondary' type='button' onClick={() => setStep(1)}>
              Atrás
            </button>
          </div>
          <div className='col pl-lg-2 pr-lg-0'>
            <button
              className='btn btn-primary'
              type='button'
              onClick={() => {
                setStep(3)
              }}
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Step2
