//@ts-check
import React, { useState, useContext, useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useLocation } from 'react-router'

import Header from 'components/atoms/Header/Header'
import Title from 'components/atoms/Title/Title'
import PhoneNumber from 'components/templates/Login/PhoneNumber'
import CodeVerification from 'components/templates/Login/CodeVerification'
import ErrorCodigo from 'components/organisms/Errors/ErrorCodigo'
import ErrorNetwork from 'components/organisms/Errors/ErrorNetwork'
import firebase from 'utils/firebaseConfig'
import { AppContext } from 'context/AppContext'
import { request } from 'services/requestService'

function Login({ history }) {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const url = query.get('source')

  const { setUser, phoneAuth, setPhoneAuth, setGetUrl } = useContext(AppContext)
  const [phone, setPhone] = useState('')
  const [countryCodeValue, setCountryCodeValue] = useState('')
  const [isAction, setIsAction] = useState(false)
  const [seconds, setSeconds] = useState(60)
  const [isLoading, setIsLoading] = useState(false)
  const [timeOutRender, setTimeOutRender] = useState(false)
  const [modals, setModals] = useState({ errCode: false, errNetwork: false })

  // Track url access source and register event in firebase
  const URL_SOURCE = {
    'qr-code': () => firebase.analytics().logEvent('acceso-codigo-qr'),
    link: () => firebase.analytics().logEvent('acceso-link-pagina'),
    sms: () => firebase.analytics().logEvent('sms-efectos-adversos'),
  }

  const URL_SOURCE_DEFAULT = () => firebase.analytics().logEvent('acceso-dominio')

  useEffect(() => {
    URL_SOURCE[url] ? URL_SOURCE[url]() : URL_SOURCE_DEFAULT()
  }, []) // eslint-disable-line

  useEffect(() => {
    setGetUrl(url)
  }, []) // eslint-disable-line

  /** This function make a countdown after firs time you register the code  */
  const countDown = () =>
    setTimeout(() => {
      setTimeOutRender(false)
      setSeconds(60)
    }, 60000)

  /**
   * @type {Object}
   */
  const props = {
    countryCodeValue,
    setCountryCodeValue,
    phone,
    setPhone,
    setIsAction,
    setIsLoading,
    isLoading,
    phoneAuth,
    setPhoneAuth,
    Auth,
    modals,
    setModals,
    history,
    seconds,
    setTimeOutRender,
    countDown,
    timeOutRender,
  }

  const propsModals = {
    modals,
    setModals,
  }

  useEffect(() => {
    /** Enable run countdown */
    if (timeOutRender) {
      setTimeout(() => setSeconds((seconds) => seconds - 1), 1000)
    }
  }, [seconds, timeOutRender])

  useEffect(() => {
    Auth.currentSession()
      .then(() => {
        request('get', 'user')
          /** if there's an user registered return data */
          .then(({ data }) => {
            setUser(data)
            history.push('/home')
          })
          .catch((err) => {
            /** if there's no an user registered go to register page */
            if (err === 'No current user') {
              return
            }
            if (err.hasOwnProperty('code')) {
              if (err.code === 'NetworkError') {
                setModals({ ...modals, errNetwork: true })
                return
              }
              if (err.code === 'ERROR_404_USER_DATA') {
                history.push('/registro')
                return
              }
            } else if (err.response.status === 404) {
              history.push('/registro')
            }
          })
      })
      .catch((err) => {
        if (err !== 'No current user') {
          if (err.hasOwnProperty('code')) {
            if (err.code === 'NetworkError') {
              setModals({ ...modals, errNetwork: true })
            }
          }
        }
      })
  }, [history, modals, setUser])

  return (
    /** Make a change between phone number and code verification */
    <>
      <Header />
      <Title title='Regístrate' />
      <>
        {isAction ? (
          <>
            <CodeVerification {...props} />
          </>
        ) : (
          <div>
            <PhoneNumber {...props} />
          </div>
        )}
      </>
      <ErrorCodigo {...propsModals} />
      <ErrorNetwork {...propsModals} />
    </>
  )
}

export default Login
