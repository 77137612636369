import React from 'react'

const DoubleSideEffect = ({ setStep }) => (
  <>
    <div className='button__primary'>
      <hr className='mb-2 d-lg-none' />
      <div className='row px-4 px-lg-5 mx-lg-5'>
        <div className='col pl-lg-0 pr-lg-2'>
          <button className='btn btn-outline-secondary' type='button' onClick={() => setStep(1)}>
            Atrás
          </button>
        </div>
        <div className='col pl-lg-2 pr-lg-0'>
          <button
            className='btn btn-primary'
            type='button'
            onClick={() => {
              setStep(3)
            }}
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  </>
)

export default DoubleSideEffect
