import { useState, useContext } from 'react'

import First from 'assets/stepper/1-steps.svg'
import AlertMessage from 'components/atoms/AlertMessage'
import Header from 'components/atoms/Header/Header'
import ButtonTakePicture from 'components/atoms/Button/ButtonTakePicture'
import ButtonPrimaryClick from 'components/atoms/Button/ButtonPrimaryClick'
import HeaderSteps from 'components/molecules/HeaderSteps'
import { AppContext } from 'context/AppContext'

function SelectPictureViewTwo({ history }) {
  const { previewFrontal, previewBack } = useContext(AppContext)
  const [show, setShow] = useState(false)
  const [bothAttached, setBothAttached] = useState(false)
  const [frontAttached, setFrontAttached] = useState(false)
  const [backAttached, setBackAttached] = useState(false)

  /** Validate if the user add the pictures  */
  const alertMessage = () => {
    if (!previewFrontal && !previewBack) {
      setBothAttached(true)
      showAlert()
    } else if (!previewFrontal) {
      showAlert()
      setFrontAttached(true)
    } else if (!previewBack) {
      setBackAttached(true)
      showAlert()
    } else history.push('/dosis-dos')
  }

  const showAlert = () => {
    setShow(true)
    setTimeout(() => setShow(false), 1000)
  }

  return (
    <>
      <Header />
      <HeaderSteps image={First} subtitle='Te solicitamos que adjuntes las fotos de tu cartilla de vacunación.' />
      <div className='px-4 mx-lg-5'>
        <ButtonTakePicture title='Frente de la cartilla' route='/previsualizar-frontal-dos' />
        <ButtonTakePicture title='Dorso de la cartilla' route='/previsualizar-reverso-dos' />
      </div>

      {show && bothAttached && <AlertMessage message='Recuerda guardar las fotos de tu cartilla' />}
      {show && frontAttached && <AlertMessage message='Guarda la foto frontal de tu cartilla' />}
      {show && backAttached && <AlertMessage message='Guarda foto posterior de tu cartilla' />}
      {!show && (
        <>
          <div className='pt-lg-4 my-4'></div>
          <div className='pt-2'></div>
        </>
      )}

      <div className='pt-3'></div>
      <div className='py-5 py-lg-0'></div>

      <div className='d-none d-lg-block'>
        <div className='pt-2'></div>
        <ButtonPrimaryClick button='Siguiente' click={alertMessage} />
      </div>

      <div className='fixed-bottom d-lg-none'>
        <ButtonPrimaryClick button='Siguiente' click={alertMessage} padding='0' />
      </div>
    </>
  )
}

export default SelectPictureViewTwo
