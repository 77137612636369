import Patient from 'assets/light/doctor-patient.svg'
import Header from 'components/atoms/Header/Header'
import ImageHero from 'components/atoms/ImageHero'
import Paragraph from 'components/atoms/Paragraph/Paragraph'
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary'
import { content } from 'utils/content'

const OnBoarding = () => {
  const { title, paragraph, button } = content[1]

  return (
    <>
      <Header />
      <ImageHero image={Patient} alt='doctor con una paciente' />
      <Paragraph title={title} paragraph={paragraph} />
      <div className='pt-lg-4'></div>
      <div className='py-5 py-lg-0'></div>
      <div className='d-none d-lg-block'>
        <ButtonPrimary button={button} route='/pasos' />
      </div>
      <div className='fixed-bottom d-lg-none'>
        <ButtonPrimary button={button} route='/pasos' padding='0' />
      </div>
    </>
  )
}

export default OnBoarding
