import Family from 'assets/light/family.svg'
import Header from 'components/atoms/Header/Header'
import ImageHero from 'components/atoms/ImageHero'
import Paragraph from 'components/atoms/Paragraph/Paragraph'
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary'
import { content } from 'utils/content'

function Start() {
  const { title, paragraph, button } = content[0]

  return (
    <>
      <Header />
      <ImageHero image={Family} alt='imagen de familia' />
      <Paragraph title={title} paragraph={paragraph} />
      <div className='py-5 py-lg-0'></div>
      <div className='d-none d-lg-block'>
        <ButtonPrimary button={button} route='/inicio' />
      </div>
      <div className='fixed-bottom d-lg-none'>
        <ButtonPrimary button={button} route='/inicio' padding='0' />
      </div>
    </>
  )
}

export default Start
