import React from 'react'

import Header from 'components/atoms/Header/Header'
import ImageHero from 'components/atoms/ImageHero'
import Paragraph from 'components/atoms/Paragraph/Paragraph'
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary'

import Phone from 'assets/light/phone.svg'
import { content } from 'utils/content'

const BeforeSteps = () => {
  const { title, paragraph, button } = content[2]

  return (
    <div>
      <Header />
      <ImageHero image={Phone} alt='imagen de celular con un icono de corazón' />
      <Paragraph title={title} paragraph={paragraph} />
      <div className='py-5 py-lg-0'></div>
      <div className='d-none d-lg-block'>
        <ButtonPrimary button={button} route='/onboarding' />
      </div>
      <div className='fixed-bottom d-lg-none'>
        <ButtonPrimary button={button} route='/onboarding' padding='0' />
      </div>
    </div>
  )
}

export default BeforeSteps
