import { useContext } from 'react'
import { Link } from 'react-router-dom'

import ArrowRight from 'assets/icons/arrowRight.svg'
import ArrowRightDisabled from 'assets/icons/arrowRightDisabled.svg'
import { AppContext } from 'context/AppContext'
import './CardHome.scss'

const CardDoses = ({ title, icon, route, alt, disabled, doses, vacId }) => {
  const { sideEffectDoses, setSideEffectDoses } = useContext(AppContext)

  const addDoses = () =>
    setSideEffectDoses({
      ...sideEffectDoses,
      doses,
      vacId,
    })

  return (
    <Link className={disabled && 'disabled'} to={route} onClick={addDoses}>
      <div className={'card__home d-flex justify-content-between align-items-center mb-3 ' + (disabled && 'disabled')}>
        <img src={icon} alt={alt} className='info__icon' />
        <p className='py-4 mb-0'>{title}</p>
        <img src={disabled ? ArrowRightDisabled : ArrowRight} alt='flecha apuntando hacia la derecha' />
      </div>
    </Link>
  )
}

export default CardDoses
