import { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

import FormPhone from 'components/organisms/Login/FormPhone'

function PhoneNumber({
  countryCodeValue,
  setCountryCodeValue,
  phone,
  setPhone,
  setIsAction,
  setIsLoading,
  isLoading,
  setPhoneAuth,
  Auth,
  setModals,
  modals,
  seconds,
  timeOutRender,
}) {
  useEffect(() => {
    setPhone('')
  }, []) // eslint-disable-line

  const onSubmit = async () => {
    if (timeOutRender) return
    setIsLoading(true)

    try {
      await Auth.signUp({
        username: countryCodeValue.concat(phone),
        password: uuidv4(),
      })
      const auth = await Auth.signIn(countryCodeValue.concat(phone))
      // New User
      setPhoneAuth(auth)
      setIsAction(true)
      setIsLoading(false)
    } catch (error) {
      // Recover user session
      try {
        const auth = await Auth.signIn(countryCodeValue.concat(phone))
        setPhoneAuth(auth)
        setIsAction(true)
        setIsLoading(false)
      } catch (error) {
        if (error.code === 'NetworkError') {
          setModals({ ...modals, errNetwork: true })
        }
      }
    }
  }

  const props = { onSubmit, phone, setPhone, countryCodeValue, setCountryCodeValue, isLoading, seconds, timeOutRender }

  return <FormPhone {...props} />
}

export default PhoneNumber
