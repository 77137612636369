import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import DatePicker, { registerLocale } from 'react-datepicker'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import moment from 'moment'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'

import { userSchema } from 'Validations/SchemaValidation'
import CustomDatePicker from 'components/atoms/Input/CustomDatePicker'
registerLocale('es', es)

function FormUserProfile({ user, setUser, userAge, handleUser, birthDate, onChange }) {
  const { register, errors, handleSubmit } = useForm({
    resolver: yupResolver(userSchema),
  })

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target
    const onChange = type === 'checkbox' ? checked : value
    setUser({ ...user, [name]: onChange, age: userAge })
  }

  return (
    <form onSubmit={handleSubmit(handleUser)}>
      <div className='px-4'>
        <div className='row'>
          <div className='col-sm-12 col-lg-6'>
            <label htmlFor='name' className='form-label mb-0 mt-lg-0'>
              Nombre
            </label>
            <input
              className={`form-control ${errors?.name && 'form-control-error'} `}
              type='text'
              name='name'
              value={_.startCase(_.toLower(user.name))}
              placeholder='Ingresa tus nombres'
              onChange={handleChange}
              ref={register}
            />
            <span className={`${errors?.name ? 'message__error' : 'text-white'} text-small d-block pl-3`}>
              Debes ingresar tus nombres
            </span>
          </div>

          <div className='col-sm-12 col-lg-6'>
            <label htmlFor='lastName' className='form-label mb-0 mt-lg-0'>
              Apellido
            </label>
            <input
              className={`form-control ${errors?.lastName && 'form-control-error'} `}
              name='lastName'
              type='text'
              value={_.startCase(_.toLower(user.lastName))}
              placeholder='Ingresa tus apellidos'
              onChange={handleChange}
              ref={register}
            />
            <span className={`${errors?.lastName ? 'message__error' : 'text-white'} text-small d-block pl-3`}>
              Debes ingresar tus apellidos
            </span>
          </div>
        </div>

        <div className='row'>
          <div className='col col-lg-3'>
            <label className='form-label mb-0' htmlFor='typeId'>
              Tipo de documento
            </label>
            <select
              className={`form-control ${errors?.documentNumber && 'form-control-error'} `}
              name='typeId'
              value={user.typeId}
              onChange={handleChange}
              ref={register}
            >
              <option value='dni'>DNI</option>
              <option value='pasaporte'>Pasaporte</option>
              <option value='extranjeria'>Cedula de Extranjería</option>
            </select>
          </div>
          <div className='col col-lg-3'>
            <label className='form-label mb-0' htmlFor='documentNumber'>
              N° de documento
            </label>
            <input
              className={`form-control ${errors?.documentNumber && 'form-control-error'} `}
              name='documentNumber'
              value={user.documentNumber}
              onChange={handleChange}
              placeholder='Ej: 11122233'
              inputMode='numeric'
              pattern='[0-9]{4,12}'
              minLength='6'
              ref={register}
            />
            <span
              className={`${errors?.documentNumber?.message ? 'message__error' : 'text-white'} text-small d-block pl-2`}
            >
              Ingresa el número
            </span>
          </div>

          <div className='col-sm-12 col-lg-6'>
            <label className='form-label mb-0' htmlFor='birthDate'>
              Fecha de nacimiento
            </label>
            <DatePicker
              type='date'
              selected={birthDate}
              value={moment(user?.birthDate).format('L')}
              onChange={onChange}
              locale='es'
              dateFormat='dd/MM/yyyy'
              placeholderText='dd/mm/aaaa'
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              minDate={new Date('1900')}
              maxDate={new Date()}
              customInput={<CustomDatePicker errorDate={false} />}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-sm-12 col-lg-6'>
            <label className='form-label mb-0' htmlFor='gender'>
              Género
            </label>
            <select
              className={`form-control ${errors?.gender && 'form-control-error'} `}
              name='gender'
              value={user.gender}
              onChange={handleChange}
              ref={register}
            >
              <option value='' disabled>
                Selecciona género
              </option>
              <option value='femenino'>Femenino</option>
              <option value='masculino'>Masculino</option>
            </select>
            <span className={`${errors?.gender?.message ? 'message__error' : 'text-white'} text-small d-block pl-3`}>
              Debes seleccionar género
            </span>
          </div>

          <div className='col-sm-12 col-lg-6'>
            <label className='form-label mb-0 ' htmlFor='email'>
              Correo electrónico
            </label>
            <input
              className={`form-control ${errors?.email && 'form-control-error'} `}
              type='email'
              value={user.email}
              name='email'
              placeholder='Ingresa tu correo electrónico'
              onChange={handleChange}
              ref={register}
            />
            <span className={`${errors?.email ? 'message__error' : 'text-white'} text-small d-block pl-3`}>
              Por favor, ingresa tu correo electrónico
            </span>
          </div>
        </div>
      </div>

      <div className='py-lg-4 my-lg-2'></div>
      <div className='pb-lg-2 mt-4'></div>
      <div className='pt-2 mt-1'>
        <div className='button__primary '>
          <hr className='mb-2 d-lg-none' />
          <div className='row px-4 px-lg-5 mx-lg-5'>
            <div className='col px-lg-0 d-none d-lg-block pl-lg-0 pr-lg-2'>
              <Link to='/home' className='btn btn-outline-secondary'>
                Atrás
              </Link>
            </div>
            <div className='col pl-lg-2 pr-lg-0'>
              <button className='btn btn-primary' type='submit'>
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default FormUserProfile
