import React from 'react'
import { useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'

import Papper from 'assets/light/papper.svg'
import ImageError from 'components/atoms/ImageError'

export default function ErrorDate({ modals, setModals }) {
  const history = useHistory()
  const dateToday = () => {
    setModals({ ...modals, errDate: false })
    history.push('/home')
  }

  return (
    <>
      <Modal
        show={modals.errDate}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        contentClassName='text-center borderadius'
      >
        <ImageError image={Papper} alt='imagen mensaje de error' style={{ width: '106px' }} />
        <p className='text-center pt-4 mb-0'>¡El día de hoy ya fueron registrados efectos adversos!</p>
        <div className='button__primary pt-lg-5 p-4'>
          <div className='px-lg-5 mx-lg-5'>
            <button onClick={dateToday} className='btn btn-block btn-primary'>
              Intenta otro día nuevamente
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
