import React, { useState, useEffect, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import _ from 'lodash'

import ArrowBack from 'assets/icons/arrowLeft.svg'
import LogoTerms from 'components/atoms/LogoTerms'
import ErrorNetwork from 'components/organisms/Errors/ErrorNetwork'
import ErrorSystem from 'components/organisms/Errors/ErrorSystem'
import ErrorSession from 'components/organisms/Errors/ErrorSession'
import FormUserProfile from 'components/organisms/Login/FormUserProfile'
import firebase from 'utils/firebaseConfig'
import { getAge } from 'utils/helpers'
import { users } from 'utils/initialState'
import { request } from 'services/requestService'
import { AppContext } from 'context/AppContext'

const UserProfile = () => {
  const { user, setUser } = useContext(AppContext)
  const [userAge, setUserAge] = useState(0)
  const [birthDate, setBirthDate] = useState('')
  const history = useHistory()
  const [modals, setModals] = useState({ errCode: false, errNetwork: false, errSession: false, errSystem: false })

  Auth.currentSession()
    .then(() => {})
    .catch(() => {
      setUser({ users })
      setModals({ ...modals, errSession: true })
      history.push('/')
    })

  const age = getAge(user)

  useEffect(() => {
    setUserAge(age)
    firebase.analytics().logEvent('perfil-usuario')
  }, [age])

  useEffect(() => {
    if (!birthDate) return
    setUser({ ...user, birthDate: moment(birthDate).format('YYYY-MM-DD'), age: userAge })
  }, [setUser, birthDate, userAge])

  const onChange = (date) => {
    setBirthDate(date)
    setUser({ ...user, birthDate: moment(birthDate).format('YYYY-MM-DD'), age: userAge })
  }

  const handleUser = () => {
    const userToLowerCase = _.mapValues(user, _.method('toLowerCase'))
    const { termsAccepted } = user
    const data = { ...userToLowerCase, termsAccepted, age: userAge, phoneNumber: user.phoneNumber }

    request('post', 'user', data)
      .then(() => {
        setUser(data)
        history.push('/home')
      })
      .catch((error) => {
        if (error.hasOwnProperty('response')) {
          if (error.response.status === 401) {
            setModals({ ...modals, errSession: true })
            return
          } else {
            setModals({ ...modals, errSystem: true })
            return
          }
        }
        if (error.hasOwnProperty('code')) {
          if (error.code === 'NetworkError') {
            setModals({ ...modals, errNetwork: true })
            return
          }
        }

        if (error === 'No current user') {
          //Error de sesión
          setModals({ ...modals, errSession: true })
          history.push('/')
          return
        }
      })
  }

  const propsModals = {
    modals,
    setModals,
  }

  const props = { user, setUser, userAge, handleUser, birthDate, onChange }

  return (
    <>
      <div className='pt-lg-3'></div>
      <LogoTerms />
      <div className='px-4 pt-3 pt-lg-0'>
        <div className='row px-3 pb-3 d-flex align-items-center mb-lg-3'>
          <div className='col-2 ml-0 pl-0 d-lg-none'>
            <Link to='/home'>
              <img src={ArrowBack} alt='flecha señalando hacia la izquierda' />
            </Link>
          </div>
          <div className='col-8 col-lg-12 conditions__title'>
            <h5 className='text-center mb-0'>Perfil</h5>
          </div>
        </div>
        <div className='pb-lg-3'></div>
      </div>
      <FormUserProfile {...props} />
      <ErrorNetwork {...propsModals} />
      <ErrorSystem {...propsModals} />
      <ErrorSession {...propsModals} />
    </>
  )
}

export default UserProfile
