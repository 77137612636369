import React from 'react'

const ButtonPrimaryClick = ({ button, click, padding = '30' }) => (
  <div className='button__primary' style={{ paddingTop: padding }}>
    <hr className='mb-2 d-lg-none' />
    <div className='px-4 px-lg-5 mx-lg-5'>
      <button onClick={click} className='btn btn-block btn-primary'>
        {button}
      </button>
    </div>
  </div>
)

export default ButtonPrimaryClick
