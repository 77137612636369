import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { ContextProvider } from 'context/AppContext'

import Login from 'components/templates/Login/Login'
import RegisterRoute from './RegisterRoute'
import TriageRoute from './TriageRoute'
import VaccineRoute from './VaccineRoute'
import SideEffectsRoute from './SideEffectsRoute'
import Loading from 'components/atoms/Loader/Loading'

const LazyHome = React.lazy(() => import('components/templates/Home'))

const AppRouter = () => (
  <ContextProvider>
    <Router>
      <Switch>
        <React.Suspense fallback={<Loading />}>
          <Route exact path='/' component={Login} />
          <Route exact path='/home' component={LazyHome} />

          <RegisterRoute />
          <TriageRoute />
          <VaccineRoute />
          <SideEffectsRoute />

          <Route exact path='*' render={() => <Redirect path='/**' to='/' />} />
        </React.Suspense>
      </Switch>
    </Router>
  </ContextProvider>
)

export default AppRouter
