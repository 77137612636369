/* eslint-disable */

 const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:38f435ee-32a4-49b5-9f1c-ccdbddfb7944",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_rXOFncua0",
    "aws_user_pools_web_client_id": "7m9kv8e1keaqbvejfgoq5fqjo6",
    "oauth": {}
};  

export default awsmobile;
