import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import Header from 'components/atoms/Header/Header'
import Title from 'components/atoms/Title/Title'
import ErrorNetwork from 'components/organisms/Errors/ErrorNetwork'
import ErrorSystem from 'components/organisms/Errors/ErrorSystem'
import ErrorSession from 'components/organisms/Errors/ErrorSession'
import FormUser from 'components/organisms/Login/FormUser'
import firebase from 'utils/firebaseConfig'
import { AppContext } from 'context/AppContext'
import { getAge } from 'utils/helpers'
import { request, suscribeNewsletter } from 'services/requestService'

function Register() {
  const history = useHistory()
  const { user, setUser, phoneAuth, userBirthDate, setUserBithDate } = useContext(AppContext)
  const [userAge, setUserAge] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [errorDate, setErrorDate] = useState(false)
  const [modals, setModals] = useState({ errCode: false, errNetwork: false, errSession: false, errSystem: false })
  const age = getAge(user)

  /**
   *
   * @param {String} date
   */
  const onChange = (date) => {
    setUserBithDate(date)
    setUser({ ...user, birthDate: moment(userBirthDate).format('YYYY-MM-DD'), age: userAge })
  }

  const dateValidation = () => {
    if (user.birthDate === 'Fecha inválida') {
      setErrorDate(true)
      return
    }
  }

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target
    const onChange = type === 'checkbox' ? checked : value
    setUser({
      ...user,
      phoneNumber: +phoneAuth.username,
      [name]: onChange,
      birthDate: moment(userBirthDate).format('YYYY-MM-DD'),
    })
  }

  useEffect(() => {
    setUserAge(age)
    firebase.analytics().logEvent('registro_nuevo-usuario')
  }, [age])

  useEffect(() => {
    if (!userBirthDate) return
    setUser({ ...user, birthDate: moment(userBirthDate).format('YYYY-MM-DD'), age: userAge })
  }, [userBirthDate])

  const handleUser = () => {
    if (user.birthDate === 'Fecha inválida') {
      setErrorDate(true)
      return
    }
    setErrorDate(false)
    setIsLoading(true)

    /** Turn all the data into uppercase to save into database */
    const userToLowerCase = _.mapValues(user, _.method('toLowerCase'))
    const { termsAccepted, newsletter } = user

    /**
     * @typedef {Object} dataToSend
     * @property {Number} age
     * @property {String} birthDate
     * @property {Number} documentNumber
     * @property {String} email
     * @property {String} gender
     * @property {String} lastName
     * @property {String} name
     * @property {Boolean} termsAccepted
     * @property {Number} phoneNumber
     * @property {Boolean} newsletter
     *
     */

    /** @type {Object} */
    const dataToSend = {
      ...userToLowerCase,
      termsAccepted,
      phoneNumber: +phoneAuth.username,
      newsletter,
      age: userAge,
    }

    if (dataToSend?.newsletter) {
      suscribeNewsletter(dataToSend.email)
    }

    request('post', 'user', dataToSend)
      .then((data) => {
        setIsLoading(false)
        setUser(dataToSend)
        history.push('/home')
      })
      .catch((error) => {
        setIsLoading(false)
        if (error.hasOwnProperty('response')) {
          if (error.response.status === 401) {
            setModals({ ...modals, errSession: true })
            return
          } else {
            setModals({ ...modals, errSystem: true })
            return
          }
        }
        if (error.hasOwnProperty('code')) {
          if (error.code === 'NetworkError') {
            setModals({ ...modals, errNetwork: true })
            return
          }
        }

        if (error === 'No current user') {
          //Error session
          setModals({ ...modals, errSession: true })
          history.push('/')
          return
        }
      })
  }

  const propsModals = {
    modals,
    setModals,
  }
  const props = { user, isLoading, onChange, handleUser, handleChange, errorDate, userBirthDate, dateValidation }

  return (
    <>
      <Header />
      <Title title='Regístrate' />
      <div className='pt-lg-4 d-lg-none'></div>
      <FormUser {...props} />
      <ErrorNetwork {...propsModals} />
      <ErrorSession {...propsModals} />
      <ErrorSystem {...propsModals} />
    </>
  )
}

export default Register
